import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";

import ErrorField from "../../Components/ErrorField";
import {
  CommonError_Msgs,
  EligibilityCriteria_Msgs,
  hyperlinkTextS11,
} from "../../Helpers/HelperText";

import InfoPopUp from "../../Components/InfoPopUp";
import { AppContext } from "../../Contexts/AppContextProvider";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  AddLocalStorageData,
  calculateDaysDifference,
  checkCARTDate,
  checkEligibileCohortTreatment,
  isStringEmpty,
  RemoveLocalStorageData,
  CompareEndofFollowUpDate,
  IsAdmin,
} from "../../Helpers/Utilities";
import DateControl from "../../Components/DateControl";
import DatePickerComponent from "../../Components/DatePickerComponent";
import dayjs from "dayjs";
import { EligibilityCriteriaService } from "../../WebApiServices/EligibilityCriteria.service";

const RRMMCriteria = () => {
  const [tecvayli_s11, setTecyali_s11] = useState();
  const [talvey_s11, setTalvey_s11] = useState();
  const [elrexfio_s11, setElrexfio_s11] = useState();
  const [linvoseltamab_s11, setLinvoseltamab_s11] = useState();
  const [carvykti_s11, setCarvykti_s11] = useState();
  const [abecma_s11, setAbecma_s11] = useState();
  const [isEligible, setIsEligible] = useState(false);
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);

  const [showHideHyperlinkModalS11, setShowHideHyperlinkModalS11] =
    useState(false);

    const pid = GetLocalStorageData("patientId") ?? 0;
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);
    const eligibilityCriteriaLocked = patientData?.eligibilityCriteriaLocked ?? false;

  const centerId = patientData?.ptCenterId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive, patientConfigData} =
  useContext(AppContext);
  const terminatedPageId = patientConfigData?.data?.terminatedPageId;
  
  const navigatePaths = {
    prevPage: "/NDMMCriteria",
    currPage: "/RRMMCriteria",
    nextPage: terminatedPageId === SideMenuItems.RRMMCriteria  ? "/ManagePatients" :
    eligibilityCriteriaLocked?"/ExclusionCriteria": 
      isAllDataFilled && isEligible
        ? "/ExclusionCriteria"
        : isAllDataFilled && !isEligible
        ? "/ManagePatients"
        : "/RRMMCriteria",
  };


  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
    // ShowPopup();
  }, []);

  const [criteriaRRMM, setCriteriaRRMM] = useState({
    userId: GetLoggedInUserID(),

    //endofFollowUpDate: "",
    lastPatientEncounter: "",
    S11_RRMM_TECVAYLI: false,
    S11_RRMM_TALVEYTM: false,
    S11_RRMM_ELREXFIOTM: false,
    S11_RRMM_Linvoseltamab: false,
    S11_RRMM_CARVYKTI: false,
    S11_RRMM_ABECMA: false,
    S11_RRMM_NOTA: null,

    S11_RRMM_TECVAYLI_RD: null,
    S11_TECVAYLI_Date: "",
    S11_RRMM_TALVEYTM_RD: null,
    S11_TALVEYTM_Date: "",
    S11_RRMM_ELREXFIOTM_RD: null,
    S11_Linvoseltamab_Date: "",
    S11_RRMM_Linvoseltamab_RD: null,
    S11_Linvoseltamab_Date: "",
    S11_RRMM_CARVYKTI_RD: null,
    S11_CARVYKTI_Date: "",
    S11_RRMM_ABECMA_RD: null,
    S11_ABECMA_Date: "",
      metInclusionCriteria: null,
      eligibilityDisabled: false,
    s4_birth_date: null,
    initiationDate1LTreatment: null,

    S11_TECVAYLI_Date_ValidError: false,
    S11_TALVEYTM_Date_ValidError: false,
    S11_ELREXFIOTM_Date_ValidError: false,
    S11_Linvoseltamab_Date_ValidError: false,
    S11_CARVYKTI_Date_ValidError: false,
    S11_ABECMA_Date_ValidError: false,
  });

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const InitialHardErrList = {
    s11Empty: false,
    s11EmptyDate: false,
    s11TecInvalidDate: false,
    s11TalInvalidDate: false,
    s11ElrInvalidDate: false,
    s11LinInvalidDate: false,
    s11CarInvalidDate: false,
    s11AbeInvalidDate: false,

  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const InitialSoftErrList = {
    s11Terminate: false,
    s11TecTerminate: false,
    s11TalTerminate: false,
    s11ElrTerminate: false,
    s11LinTerminate: false,
    s11CarTerminate: false,
    s11AbeTerminate: false,

  };
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);

  async function LoadData() {
    try {
      if (pid > 0) {
        // Fetch data from the service
        const response = await EligibilityCriteriaService.LoadRRMMCriteria(pid);

        if (response?.status === HTTPResponse.OK) {
          const data = response.data;
          // Update the state with the fetched data
          setCriteriaRRMM((prev) => ({
            ...prev,

           // endofFollowUpDate: data.endofFollowUpDate,
              lastPatientEncounter: data.lastPatientEncounterDate,
              eligibilityDisabled: data.eligibilityLocked,
            S11_TECVAYLI_Date_ValidError: false,
            S11_TALVEYTM_Date_ValidError: false,
            S11_ELREXFIOTM_Date_ValidError: false,
            S11_Linvoseltamab_Date_ValidError: false,
            S11_CARVYKTI_Date_ValidError: false,
            S11_ABECMA_Date_ValidError: false,

            S11_RRMM_TECVAYLI: data.cartTherapyTecvayli,
            S11_RRMM_TALVEYTM: data.cartTherapyTalvey,
            S11_RRMM_ELREXFIOTM: data.cartTherapyElrexfio,
            S11_RRMM_Linvoseltamab: data.cartTherapyLinvoseltamab,
            S11_RRMM_CARVYKTI: data.cartTherapyCarvykti,
            S11_RRMM_ABECMA: data.cartTherapyAbecma,
            S11_RRMM_NOTA:
              data.cartTherapyNone != null
                ? data.cartTherapyNone == true
                  ? 1
                  : 0
                : null,
            S11_TECVAYLI_Date: data.indexdateCartTherapyTecvayli
              ? data.indexdateCartTherapyTecvayli
              : null,
            S11_RRMM_TECVAYLI_RD: data.indexdateCartTherapyTecvayliUnk
              ? 99
              : data.indexdateCartTherapyTecvayli
              ? 1
              : null,

            S11_TALVEYTM_Date: data.indexdateCartTherapyTalvey
              ? data.indexdateCartTherapyTalvey
              : null,
            S11_RRMM_TALVEYTM_RD: data.indexdateCartTherapyTalveyUnk
              ? 99
              : data.indexdateCartTherapyTalvey
              ? 1
              : null,

            S11_ELREXFIOTM_Date: data.indexdateCartTherapyElrexfio
              ? data.indexdateCartTherapyElrexfio
              : null,
            S11_RRMM_ELREXFIOTM_RD: data.indexdateCartTherapyElrexfioUnk
              ? 99
              : data.indexdateCartTherapyElrexfio
              ? 1
              : null,

            S11_Linvoseltamab_Date: data.indexdateCartTherapyLinvoseltamab
              ? data.indexdateCartTherapyLinvoseltamab
              : null,
            S11_RRMM_Linvoseltamab_RD: data.indexdateCartTherapyLinvoseltamabUnk
              ? 99
              : data.indexdateCartTherapyLinvoseltamab
              ? 1
              : null,

            S11_CARVYKTI_Date: data.indexdateCartTherapyCarvykti
              ? data.indexdateCartTherapyCarvykti
              : null,
            S11_RRMM_CARVYKTI_RD: data.indexdateCartTherapyCarvyktiUnk
              ? 99
              : data.indexdateCartTherapyCarvykti
              ? 1
              : null,

            S11_ABECMA_Date: data.indexdateCartTherapyAbecma
              ? data.indexdateCartTherapyAbecma
              : null,
            S11_RRMM_ABECMA_RD: data.indexdateCartTherapyAbecmaUnk
              ? 99
              : data.indexdateCartTherapyAbecma
              ? 1
              : null,

            s4_birth_date: !isStringEmpty(data.birthDate)
              ? data.birthDate
              : null,

           // metInclusionCriteria: data.metInclusionCriteria,

            initiationDate1LTreatment: data.initiationDate1LTreatment
              ? data.initiationDate1LTreatment
              : null,
          }));

          if (data.cohortTreatmentModelList.length > 0)
            AddLocalStorageData(
              "CohortTreatment",
              JSON.stringify(data.cohortTreatmentModelList)
            );
          else AddLocalStorageData("CohortTreatment", JSON.stringify([]));

          //display ineligible cohort message on load

          const maxDays = 6575;
          var S11TecIneligible = false;
          var S11TalIneligible = false;
          var S11ELRIneligible = false;
          var S11LinIneligible = false;
          var S11CarIneligible = false;
          var S11AbeIneligible = false;
          var S11NoneIneligible = false;
          //Tec
          if (
            data.indexdateCartTherapyTecvayliUnk !== null &&
            data.indexdateCartTherapyTecvayliUnk == false &&
            data.indexdateCartTherapyTecvayli != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyTecvayli
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyTecvayli).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11TecIneligible = true;
            } else if (
              checkEligibileCohortTreatment(
                data.indexdateCartTherapyTecvayli,
                "2022/10/25"
              )
            ) {
              S11TecIneligible = true;
            } else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyTecvayli,
                data.initiationDate1LTreatment
              )
            ) {
              S11TecIneligible = true;
            }
          } else if (data.indexdateCartTherapyTecvayliUnk == true)
            S11TecIneligible = true;

          //Tal
          if (
            data.indexdateCartTherapyTalveyUnk !== null &&
            data.indexdateCartTherapyTalveyUnk == false &&
            data.indexdateCartTherapyTalvey != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyTalvey
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyTalvey).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11TalIneligible = true;
            } else if (
              checkEligibileCohortTreatment(
                data.indexdateCartTherapyTalvey,
                "2023/08/09"
              )
            ) {
              S11TalIneligible = true;
            } else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyTalvey,
                data.initiationDate1LTreatment
              )
            ) {
              S11TalIneligible = true;
            }
          } else if (data.indexdateCartTherapyTalveyUnk == true)
            S11TalIneligible = true;

          //ELR
          if (
            data.indexdateCartTherapyElrexfioUnk !== null &&
            data.indexdateCartTherapyElrexfioUnk == false &&
            data.indexdateCartTherapyElrexfio != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyElrexfio
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyElrexfio).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11ELRIneligible = true;
            } else if (
              checkEligibileCohortTreatment(
                data.indexdateCartTherapyElrexfio,
                "2023/08/14"
              )
            ) {
              S11ELRIneligible = true;
            } else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyElrexfio,
                data.initiationDate1LTreatment
              )
            ) {
              S11ELRIneligible = true;
            }
          } else if (data.indexdateCartTherapyElrexfioUnk == true)
            S11ELRIneligible = true;

          //Lin
          if (
            data.indexdateCartTherapyLinvoseltamabUnk !== null &&
            data.indexdateCartTherapyLinvoseltamabUnk == false &&
            data.indexdateCartTherapyLinvoseltamab != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyLinvoseltamab
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyLinvoseltamab).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11LinIneligible = true;
            }
            //FDA Approval Date Validation
            //else if (
            //  checkEligibileCohortTreatment(
            //    data.indexdateCartTherapyLinvoseltamab,
            //    surveyDate
            //  )
            //) {
            //  S11LinIneligible = true;
            //}

            else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyLinvoseltamab,
                data.initiationDate1LTreatment
              )
            ) {
              S11LinIneligible = true;
            }
          } else if (data.indexdateCartTherapyLinvoseltamabUnk == true)
            S11LinIneligible = true;

          //Car
          if (
            data.indexdateCartTherapyCarvyktiUnk !== null &&
            data.indexdateCartTherapyCarvyktiUnk == false &&
            data.indexdateCartTherapyCarvykti != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyCarvykti
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyCarvykti).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11CarIneligible = true;
            } else if (
              checkEligibileCohortTreatment(
                data.indexdateCartTherapyCarvykti,
                "2022/02/28"
              )
            ) {
              S11CarIneligible = true;
            } else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyCarvykti,
                data.initiationDate1LTreatment
              )
            ) {
              S11CarIneligible = true;
            }
          } else if (data.indexdateCartTherapyCarvyktiUnk == true)
            S11CarIneligible = true;

          //Abe
          if (
            data.indexdateCartTherapyAbecmaUnk !== null &&
            data.indexdateCartTherapyAbecmaUnk == false &&
            data.indexdateCartTherapyAbecma != ""
          ) {
            //const daysDifference = calculateDaysDifference(
            //  data.birthDate,
            //  data.indexdateCartTherapyAbecma
            //);
            //if (daysDifference < maxDays) {
              let IndexDateYear = (new Date(data.indexdateCartTherapyAbecma).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S11AbeIneligible = true;
            } else if (
              checkEligibileCohortTreatment(
                data.indexdateCartTherapyAbecma,
                "2021/03/26"
              )
            ) {
              S11AbeIneligible = true;
            } else if (
              data.initiationDate1LTreatment != "" &&
              data.initiationDate1LTreatment != null &&
              checkCARTDate(
                data.indexdateCartTherapyAbecma,
                data.initiationDate1LTreatment
              )
            ) {
              S11AbeIneligible = true;
            }
          } else if (data.indexdateCartTherapyAbecmaUnk == true)
            S11AbeIneligible = true;

          //None
          if (data.cartTherapyNone !== null && data.cartTherapyNone == true)
            S11NoneIneligible = true;

          setSoftErrList((prev) => ({
            ...prev,
            s11TecTerminate: S11TecIneligible,
            s11TalTerminate: S11TalIneligible,
            s11ElrTerminate: S11ELRIneligible,
            s11LinTerminate: S11LinIneligible,
            s11CarTerminate: S11CarIneligible,
            s11AbeTerminate: S11AbeIneligible,
            s11Terminate: S11NoneIneligible,
          }));
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const HandleFieldChange = (e) => {
    const { name, id, value } = e.target;
    switch (name) {
      case "S11_RRMM_NOTA": {
        const value_int = Number(value);

        if (value_int === 1) {
          setCriteriaRRMM((prev) => ({
            ...prev,
            [name]: Number(value), //value,
            S11_RRMM_TECVAYLI: false,
            S11_RRMM_TALVEYTM: false,
            S11_RRMM_ELREXFIOTM: false,
            S11_RRMM_Linvoseltamab: false,
            S11_RRMM_CARVYKTI: false,
            S11_RRMM_ABECMA: false,
            S11_TECVAYLI_Date: "", // Reset date for TECVAYLI
            S11_RRMM_TECVAYLI_RD: null, // Reset radio button for TECVAYLI
            S11_TALVEYTM_Date: "", // Reset date for TALVEYTM
            S11_RRMM_TALVEYTM_RD: null, // Reset radio button for TALVEYTM
            S11_ELREXFIOTM_Date: "", // Reset date for ELREXFIOTM
            S11_RRMM_ELREXFIOTM_RD: null, // Reset radio button for ELREXFIOTM
            S11_Linvoseltamab_Date: "", // Reset date for Linvoseltamab
            S11_RRMM_Linvoseltamab_RD: null, // Reset radio button for Linvoseltamab
            S11_CARVYKTI_Date: "", // Reset date for CARVYKTI
            S11_RRMM_CARVYKTI_RD: null, // Reset radio button for CARVYKTI
            S11_ABECMA_Date: "", // Reset date for ABECMA
            S11_RRMM_ABECMA_RD: null, // Reset radio button for ABECMA
          }));

          setSoftErrList((prev) => ({
            ...prev,
            s11Terminate: true,
          }));
        } else {
          setSoftErrList((prev) => ({
            ...prev,
            s11Terminate: false,
          }));
        }

        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11AbeInvalidDate: false,
            s11CarInvalidDate: false,
            s11ElrInvalidDate: false,
            s11LinInvalidDate: false,
            s11TalInvalidDate: false,
            s11TecInvalidDate: false,
        }));

        break;
      }

      case "S11_RRMM_TECVAYLI":
        const isTecvayliChecked = !criteriaRRMM.S11_RRMM_TECVAYLI;

        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isTecvayliChecked,
          S11_TECVAYLI_Date: isTecvayliChecked ? prev.S11_TECVAYLI_Date : "", // Reset date if unchecked
          S11_RRMM_TECVAYLI_RD: isTecvayliChecked
            ? prev.S11_RRMM_TECVAYLI_RD
            : null, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_TECVAYLI_Date_ValidError: false,
        }));
        setTecyali_s11(isTecvayliChecked); // Toggle visibility of radio buttons
        setHardErrList((prev) => ({
          ...prev,
          s11EmptyDate: false,
            s11Empty: false,
            s11TecInvalidDate: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11TecTerminate: false,
        }));

        break;

      case "S11_RRMM_TECVAYLI_RD": {
        const value_int = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_TECVAYLI_Date: "",
          S11_TECVAYLI_Date_ValidError: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11TecInvalidDate: false,
        }));

        setTecyali_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11TecTerminate: value_int == 99,
          s11Terminate: false,
        }));

        break;
      }

      case "S11_RRMM_TALVEYTM": {
        const isTalveytmChecked = !criteriaRRMM.S11_RRMM_TALVEYTM;
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isTalveytmChecked,
          S11_TALVEYTM_Date: isTalveytmChecked ? "" : prev.S11_TALVEYTM_Date, // Reset date if unchecked
          S11_RRMM_TALVEYTM_RD: isTalveytmChecked
            ? null
            : prev.S11_RRMM_TALVEYTM_RD, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_TALVEYTM_Date_ValidError: false,
        }));
        setTalvey_s11(isTalveytmChecked); // Toggle visibility of radio buttons

        setHardErrList((prev) => ({
          ...prev,
          s11EmptyDate: false,
            s11Empty: false,
            s11TalInvalidDate: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11TalTerminate: false,
        }));

        break;
      }
      case "S11_RRMM_TALVEYTM_RD": {
        const value_int = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_TALVEYTM_Date: "",
          S11_TALVEYTM_Date_ValidError: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11TalInvalidDate: false,
        }));

        setTalvey_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11TalTerminate: value_int == 99,
          s11Terminate: false,
        }));

        break;
      }

      case "S11_RRMM_ELREXFIOTM": {
        const isElrexfiotmChecked = !criteriaRRMM.S11_RRMM_ELREXFIOTM;
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isElrexfiotmChecked,
          S11_ELREXFIOTM_Date: isElrexfiotmChecked
            ? prev.S11_ELREXFIOTM_Date
            : "", // Reset date if unchecked
          S11_RRMM_ELREXFIOTM_RD: isElrexfiotmChecked
            ? prev.S11_RRMM_ELREXFIOTM_RD
            : null, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_ELREXFIOTM_Date_ValidError: false,
        }));
        setElrexfio_s11(isElrexfiotmChecked); // Toggle visibility of radio buttons
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11ElrTerminate: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11ElrInvalidDate: false,
        }));
        break;
      }
      case "S11_RRMM_ELREXFIOTM_RD": {
        const value_int = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_ELREXFIOTM_Date: "",
          S11_ELREXFIOTM_Date_ValidError: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11ElrInvalidDate: false,
        }));

        setElrexfio_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11ElrTerminate: value_int == 99,
          s11Terminate: false,
        }));

        // checkError();
        break;
      }

      case "S11_RRMM_Linvoseltamab":
        const isLinvoseltamabChecked = !criteriaRRMM.S11_RRMM_Linvoseltamab;
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isLinvoseltamabChecked,
          S11_Linvoseltamab_Date: isLinvoseltamabChecked
            ? ""
            : prev.S11_Linvoseltamab_Date, // Reset date if unchecked
          S11_RRMM_Linvoseltamab_RD: isLinvoseltamabChecked
            ? null
            : prev.S11_RRMM_Linvoseltamab_RD, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_Linvoseltamab_Date_ValidError: false,
        }));
        setLinvoseltamab_s11(isLinvoseltamabChecked); // Toggle visibility of radio buttons
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11LinTerminate: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11LinInvalidDate: false,
        }));
        break;

      case "S11_RRMM_Linvoseltamab_RD":
        const value_int_linvoseltamab = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_Linvoseltamab_Date: "",
          S11_Linvoseltamab_Date_ValidError: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11LinInvalidDate: false,
        }));

        setLinvoseltamab_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11LinTerminate: value_int_linvoseltamab == 99,
          s11Terminate: false,
        }));

        // checkError();

        break;

      case "S11_RRMM_CARVYKTI":
        const isCarvyktiChecked = !criteriaRRMM.S11_RRMM_CARVYKTI;
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isCarvyktiChecked,
          S11_CARVYKTI_Date: isCarvyktiChecked ? "" : prev.S11_CARVYKTI_Date, // Reset date if unchecked
          S11_RRMM_CARVYKTI_RD: isCarvyktiChecked
            ? null
            : prev.S11_RRMM_CARVYKTI_RD, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_CARVYKTI_Date_ValidError: false,
        }));
        setCarvykti_s11(isCarvyktiChecked); // Toggle visibility of radio buttons
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11CarTerminate: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11CarInvalidDate: false,
        }));
        break;

      case "S11_RRMM_CARVYKTI_RD":
        const value_int_carvykti = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_CARVYKTI_Date: "",
          S11_CARVYKTI_Date_ValidError: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11CarInvalidDate: false,
        }));

        setCarvykti_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11CarTerminate: value_int_carvykti == 99,
          s11Terminate: false,
        }));

        // checkError();
        break;

      case "S11_RRMM_ABECMA":
        const isAbecmaChecked = !criteriaRRMM.S11_RRMM_ABECMA;
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: isAbecmaChecked,
          S11_ABECMA_Date: isAbecmaChecked ? "" : prev.S11_ABECMA_Date, // Reset date if unchecked
          S11_RRMM_ABECMA_RD: isAbecmaChecked ? null : prev.S11_RRMM_ABECMA_RD, // Reset radio button if unchecked
          S11_RRMM_NOTA: null,
          S11_ABECMA_Date_ValidError: false,
        }));
        setAbecma_s11(isAbecmaChecked); // Toggle visibility of radio buttons
        setSoftErrList((prev) => ({
          ...prev,
          s11Terminate: false,
          s11AbeTerminate: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11AbeInvalidDate: false,
        }));
        break;

      case "S11_RRMM_ABECMA_RD":
        const value_int_abecma = Number(value);
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          S11_ABECMA_Date: "",
          S11_ABECMA_Date_ValidError: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          s11Empty: false,
            s11EmptyDate: false,
            s11AbeInvalidDate: false,
        }));

        setAbecma_s11(true);
        setSoftErrList((prev) => ({
          ...prev,
          s11AbeTerminate: value_int_abecma == 99,
          s11Terminate: false,
        }));

        // checkError();
        break;

      default:
        break;
    }
  };

  function checkError() {
    if (
      (criteriaRRMM.S11_RRMM_TECVAYLI &&
        criteriaRRMM.S11_RRMM_TECVAYLI_RD === 99) ||
      (criteriaRRMM.S11_RRMM_TALVEYTM &&
        criteriaRRMM.S11_RRMM_TALVEYTM_RD === 99) ||
      (criteriaRRMM.S11_RRMM_ELREXFIOTM &&
        criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 99) ||
      (criteriaRRMM.S11_RRMM_Linvoseltamab &&
        criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 99) ||
      (criteriaRRMM.S11_RRMM_CARVYKTI &&
        criteriaRRMM.S11_RRMM_CARVYKTI_RD === 99) ||
      (criteriaRRMM.S11_RRMM_ABECMA && criteriaRRMM.S11_RRMM_ABECMA_RD === 99)
    ) {
      setSoftErrList((prev) => ({
        ...prev,

        s11Terminate: true,
      }));
    }

    if (
      !criteriaRRMM.S11_RRMM_TECVAYLI &&
      criteriaRRMM.S11_RRMM_TECVAYLI_RD !== 99 &&
      !criteriaRRMM.S11_RRMM_TALVEYTM &&
      criteriaRRMM.S11_RRMM_TALVEYTM_RD !== 99 &&
      !criteriaRRMM.S11_RRMM_ELREXFIOTM &&
      criteriaRRMM.S11_RRMM_ELREXFIOTM_RD !== 99 &&
      !criteriaRRMM.S11_RRMM_Linvoseltamab &&
      criteriaRRMM.S11_RRMM_Linvoseltamab_RD !== 99 &&
      !criteriaRRMM.S11_RRMM_CARVYKTI &&
      criteriaRRMM.S11_RRMM_CARVYKTI_RD !== 99 &&
      !criteriaRRMM.S11_RRMM_ABECMA &&
      criteriaRRMM.S11_RRMM_ABECMA_RD !== 99
    ) {
      setSoftErrList((prev) => ({
        ...prev,

        s11Terminate: false,
      }));
    }
  }

    const DateInRange = (date, minDate, maxDate) => {
        return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
    };


  const ValidateForm = () => {
    let isValid = true;
    if (
      (criteriaRRMM.S11_RRMM_NOTA === null ||
        criteriaRRMM.S11_RRMM_NOTA === false) &&
      !criteriaRRMM.S11_RRMM_TECVAYLI &&
      !criteriaRRMM.S11_RRMM_TALVEYTM &&
      !criteriaRRMM.S11_RRMM_ELREXFIOTM &&
      !criteriaRRMM.S11_RRMM_Linvoseltamab &&
      !criteriaRRMM.S11_RRMM_CARVYKTI &&
      !criteriaRRMM.S11_RRMM_ABECMA
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s11Empty: true,
      }));
      isValid = false;
    }

    if (
      (criteriaRRMM.S11_RRMM_TECVAYLI &&
        criteriaRRMM.S11_RRMM_TECVAYLI_RD === null) ||
      (criteriaRRMM.S11_RRMM_TALVEYTM &&
        criteriaRRMM.S11_RRMM_TALVEYTM_RD === null) ||
      (criteriaRRMM.S11_RRMM_ELREXFIOTM &&
        criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === null) ||
      (criteriaRRMM.S11_RRMM_Linvoseltamab &&
        criteriaRRMM.S11_RRMM_Linvoseltamab_RD === null) ||
      (criteriaRRMM.S11_RRMM_CARVYKTI &&
        criteriaRRMM.S11_RRMM_CARVYKTI_RD === null) ||
      (criteriaRRMM.S11_RRMM_ABECMA && criteriaRRMM.S11_RRMM_ABECMA_RD === null)
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s11EmptyDate: true,
      }));
      isValid = false;
    }

    if (
      (criteriaRRMM.S11_RRMM_TECVAYLI &&
        criteriaRRMM.S11_RRMM_TECVAYLI_RD === 1 &&
        (criteriaRRMM.S11_TECVAYLI_Date === null ||
          criteriaRRMM.S11_TECVAYLI_Date === "")) ||
      (criteriaRRMM.S11_RRMM_TALVEYTM &&
        criteriaRRMM.S11_RRMM_TALVEYTM_RD === 1 &&
        (criteriaRRMM.S11_TALVEYTM_Date === null ||
          criteriaRRMM.S11_TALVEYTM_Date === "")) ||
      (criteriaRRMM.S11_RRMM_ELREXFIOTM &&
        criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 1 &&
        (criteriaRRMM.S11_ELREXFIOTM_Date === null ||
          criteriaRRMM.S11_ELREXFIOTM_Date === "")) ||
      (criteriaRRMM.S11_RRMM_Linvoseltamab &&
        criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 1 &&
        (criteriaRRMM.S11_Linvoseltamab_Date === null ||
          criteriaRRMM.S11_Linvoseltamab_Date === "")) ||
      (criteriaRRMM.S11_RRMM_CARVYKTI &&
        criteriaRRMM.S11_RRMM_CARVYKTI_RD === 1 &&
        (criteriaRRMM.S11_CARVYKTI_Date === null ||
          criteriaRRMM.S11_CARVYKTI_Date === "")) ||
      (criteriaRRMM.S11_RRMM_ABECMA &&
        criteriaRRMM.S11_RRMM_ABECMA_RD === 1 &&
        (criteriaRRMM.S11_ABECMA_Date === null ||
          criteriaRRMM.S11_ABECMA_Date === ""))
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s11EmptyDate: true,
      }));
      isValid = false;
      }


      //Techvayli
      if (criteriaRRMM.S11_RRMM_TECVAYLI &&
          criteriaRRMM.S11_RRMM_TECVAYLI_RD === 1 &&
          (criteriaRRMM.S11_TECVAYLI_Date !== null ||
              criteriaRRMM.S11_TECVAYLI_Date !== "") && criteriaRRMM.S11_TECVAYLI_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11TecInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_TECVAYLI &&
          criteriaRRMM.S11_RRMM_TECVAYLI_RD === 1 &&
          (criteriaRRMM.S11_TECVAYLI_Date !== null ||
              criteriaRRMM.S11_TECVAYLI_Date !== "") &&
          criteriaRRMM.S11_TECVAYLI_Date && !DateInRange(
              criteriaRRMM.S11_TECVAYLI_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11TecInvalidDate: true,
          }));
          isValid = false;
      }

      //Talvey
      if (criteriaRRMM.S11_RRMM_TALVEYTM &&
          criteriaRRMM.S11_RRMM_TALVEYTM_RD === 1 &&
          (criteriaRRMM.S11_TALVEYTM_Date !== null ||
              criteriaRRMM.S11_TALVEYTM_Date !== null) && criteriaRRMM.S11_TALVEYTM_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11TalInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_TALVEYTM &&
          criteriaRRMM.S11_RRMM_TALVEYTM_RD === 1 &&
          (criteriaRRMM.S11_TALVEYTM_Date !== null ||
              criteriaRRMM.S11_TALVEYTM_Date !== null) &&
          criteriaRRMM.S11_TALVEYTM_Date && !DateInRange(
              criteriaRRMM.S11_TALVEYTM_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11TalInvalidDate: true,
          }));
          isValid = false;
      }


      //Elexfrio
      if (criteriaRRMM.S11_RRMM_ELREXFIOTM &&
          criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 1 &&
          criteriaRRMM.S11_ELREXFIOTM_Date != null &&
          criteriaRRMM.S11_ELREXFIOTM_Date != "" && criteriaRRMM.S11_ELREXFIOTM_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11ElrInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_ELREXFIOTM &&
          criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 1 &&
          criteriaRRMM.S11_ELREXFIOTM_Date != null &&
          criteriaRRMM.S11_ELREXFIOTM_Date != "" &&
          criteriaRRMM.S11_ELREXFIOTM_Date && !DateInRange(
              criteriaRRMM.S11_ELREXFIOTM_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11ElrInvalidDate: true,
          }));
          isValid = false;
      }


      //Linvoseltamab
      if (criteriaRRMM.S11_RRMM_Linvoseltamab &&
          criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 1 &&
          criteriaRRMM.S11_Linvoseltamab_Date !== null &&
          criteriaRRMM.S11_Linvoseltamab_Date !== "" && criteriaRRMM.S11_Linvoseltamab_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11LinInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_Linvoseltamab &&
          criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 1 &&
          criteriaRRMM.S11_Linvoseltamab_Date !== null &&
          criteriaRRMM.S11_Linvoseltamab_Date !== "" &&
          criteriaRRMM.S11_Linvoseltamab_Date && !DateInRange(
              criteriaRRMM.S11_Linvoseltamab_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11LinInvalidDate: true,
          }));
          isValid = false;
      }


      //Carvykti
      if (criteriaRRMM.S11_RRMM_CARVYKTI &&
          criteriaRRMM.S11_RRMM_CARVYKTI_RD === 1 &&
          criteriaRRMM.S11_CARVYKTI_Date !== null &&
          criteriaRRMM.S11_CARVYKTI_Date !== "" && criteriaRRMM.S11_CARVYKTI_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11CarInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_CARVYKTI &&
          criteriaRRMM.S11_RRMM_CARVYKTI_RD === 1 &&
          criteriaRRMM.S11_CARVYKTI_Date !== null &&
          criteriaRRMM.S11_CARVYKTI_Date !== "" &&
          criteriaRRMM.S11_CARVYKTI_Date && !DateInRange(
              criteriaRRMM.S11_CARVYKTI_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11CarInvalidDate: true,
          }));
          isValid = false;
      }



      //Abecma
      if (criteriaRRMM.S11_RRMM_ABECMA &&
          criteriaRRMM.S11_RRMM_ABECMA_RD === 1 &&
          criteriaRRMM.S11_ABECMA_Date !== null &&
          criteriaRRMM.S11_ABECMA_Date !== "" && criteriaRRMM.S11_ABECMA_Date === "Invalid Date") {
          setHardErrList((prev) => ({
              ...prev,
              s11AbeInvalidDate: true,
          }));
          isValid = false;
      }
      else if (criteriaRRMM.S11_RRMM_ABECMA &&
          criteriaRRMM.S11_RRMM_ABECMA_RD === 1 &&
          criteriaRRMM.S11_ABECMA_Date !== null &&
          criteriaRRMM.S11_ABECMA_Date !== "" &&
          criteriaRRMM.S11_ABECMA_Date && !DateInRange(
              criteriaRRMM.S11_ABECMA_Date, dayjs("01-01-1920"), dayjs()
          )
      ) {

          setHardErrList((prev) => ({
              ...prev,
              s11AbeInvalidDate: true,
          }));
          isValid = false;
      }



    if (
      criteriaRRMM.S11_RRMM_TECVAYLI &&
      criteriaRRMM.S11_RRMM_TECVAYLI_RD === 1 &&
      (criteriaRRMM.S11_TECVAYLI_Date !== null ||
        criteriaRRMM.S11_TECVAYLI_Date !== "") &&
      criteriaRRMM.S11_TECVAYLI_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_TECVAYLI_Date_ValidError: true,
      }));
      isValid = false;
    } else if (
      criteriaRRMM.S11_RRMM_TALVEYTM &&
      criteriaRRMM.S11_RRMM_TALVEYTM_RD === 1 &&
      (criteriaRRMM.S11_TALVEYTM_Date !== null ||
        criteriaRRMM.S11_TALVEYTM_Date !== null) &&
      criteriaRRMM.S11_TALVEYTM_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_TALVEYTM_Date_ValidError: true,
      }));
      isValid = false;
    } else if (
      criteriaRRMM.S11_RRMM_ELREXFIOTM &&
      criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 1 &&
      criteriaRRMM.S11_ELREXFIOTM_Date != null &&
      criteriaRRMM.S11_ELREXFIOTM_Date != "" &&
      criteriaRRMM.S11_ELREXFIOTM_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_ELREXFIOTM_Date_ValidError: true,
      }));
      isValid = false;
    } else if (
      criteriaRRMM.S11_RRMM_Linvoseltamab &&
      criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 1 &&
      criteriaRRMM.S11_Linvoseltamab_Date !== null &&
      criteriaRRMM.S11_Linvoseltamab_Date !== "" &&
      criteriaRRMM.S11_Linvoseltamab_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_Linvoseltamab_Date_ValidError: true,
      }));
      isValid = false;
    } else if (
      criteriaRRMM.S11_RRMM_CARVYKTI &&
      criteriaRRMM.S11_RRMM_CARVYKTI_RD === 1 &&
      criteriaRRMM.S11_CARVYKTI_Date !== null &&
      criteriaRRMM.S11_CARVYKTI_Date !== "" &&
      criteriaRRMM.S11_CARVYKTI_Date &&
      criteriaRRMM.S11_CARVYKTI_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_CARVYKTI_Date_ValidError: true,
      }));
      isValid = false;
    } else if (
      criteriaRRMM.S11_RRMM_ABECMA &&
      criteriaRRMM.S11_RRMM_ABECMA_RD === 1 &&
      criteriaRRMM.S11_ABECMA_Date !== null &&
      criteriaRRMM.S11_ABECMA_Date !== "" &&
      criteriaRRMM.S11_ABECMA_Date_ValidError
    ) {
      setCriteriaRRMM((prev) => ({
        ...prev,
        S11_ABECMA_Date_ValidError: true,
      }));
      isValid = false;
    }
    setIsAllDataFilled(isValid);

    return isValid;
  };

    const SavePage = async (validate) => {
    try {
      setHardErrList(InitialHardErrList);
      setSoftErrList(InitialSoftErrList);
      ToggleLoader(true);

      let isValid = validate ? ValidateForm() : true;
      if (!isValid) {
        setShowTopErrMsg(true);
        ToggleLoader(false);
        return false;
      }
      setShowTopErrMsg(false);

      const storedData = GetLocalStorageData("CohortTreatment");

      let cohortTreatmentModelListt = [];
      if (storedData) {
        cohortTreatmentModelListt = JSON.parse(storedData);
        }
      // if (isValid) {
        //((criteriaRRMM.S11_RRMM_NOTA == 0 ||
        //    criteriaRRMM.S11_RRMM_NOTA == null) &&
        //    cohortTreatmentModelListt.length > 0) ||
      const eligibilityCriteriademographicModel = {
        patientId: pid,
        centerId: centerId,
        userId: GetLoggedInUserID(),
        cartTherapyTecvayli: criteriaRRMM.S11_RRMM_TECVAYLI === true ? 1 : 0,
        cartTherapyTalvey: criteriaRRMM.S11_RRMM_TALVEYTM === true ? 1 : 0,
        cartTherapyElrexfio: criteriaRRMM.S11_RRMM_ELREXFIOTM === true ? 1 : 0,
        cartTherapyLinvoseltamab:
          criteriaRRMM.S11_RRMM_Linvoseltamab === true ? 1 : 0,
        cartTherapyCarvykti: criteriaRRMM.S11_RRMM_CARVYKTI === true ? 1 : 0,
        cartTherapyAbecma: criteriaRRMM.S11_RRMM_ABECMA === true ? 1 : 0,
        cartTherapyNone: criteriaRRMM.S11_RRMM_NOTA,

        indexdateCartTherapyTecvayli: criteriaRRMM.S11_TECVAYLI_Date,
        indexdateCartTherapyTecvayliUnk:
          criteriaRRMM.S11_RRMM_TECVAYLI_RD === 99 ? true : false,
        indexdateCartTherapyTalvey: criteriaRRMM.S11_TALVEYTM_Date,
        indexdateCartTherapyTalveyUnk:
          criteriaRRMM.S11_RRMM_TALVEYTM_RD === 99 ? true : false,
        indexdateCartTherapyElrexfio: criteriaRRMM.S11_ELREXFIOTM_Date,
        indexdateCartTherapyElrexfioUnk:
          criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 99 ? true : false,
        indexdateCartTherapyLinvoseltamab: criteriaRRMM.S11_Linvoseltamab_Date,
        indexdateCartTherapyLinvoseltamabUnk:
          criteriaRRMM.S11_RRMM_Linvoseltamab_RD === 99 ? true : false,
        indexdateCartTherapyCarvykti: criteriaRRMM.S11_CARVYKTI_Date,
        indexdateCartTherapyCarvyktiUnk:
          criteriaRRMM.S11_RRMM_CARVYKTI_RD === 99 ? true : false,
        indexdateCartTherapyAbecma: criteriaRRMM.S11_ABECMA_Date,
        indexdateCartTherapyAbecmaUnk:
          criteriaRRMM.S11_RRMM_ABECMA_RD === 99 ? true : false,
        cohortTreatmentModelList: [],
        metInclusionCriteria:
            ((criteriaRRMM.S11_RRMM_NOTA == 0 ||
                criteriaRRMM.S11_RRMM_NOTA == null) &&
                ((criteriaRRMM.S11_RRMM_TECVAYLI === true && softErrList.s11TecTerminate == false) ||
                (criteriaRRMM.S11_RRMM_TALVEYTM === true && softErrList.s11TalTerminate == false) ||
                (criteriaRRMM.S11_RRMM_ELREXFIOTM === true && softErrList.s11ElrTerminate == false) ||
                (criteriaRRMM.S11_RRMM_Linvoseltamab === true && softErrList.s11LinTerminate == false) ||
                (criteriaRRMM.S11_RRMM_CARVYKTI === true && softErrList.s11CarTerminate == false) ||
                (criteriaRRMM.S11_RRMM_ABECMA === true && softErrList.s11AbeTerminate == false) )) ||

          (cohortTreatmentModelListt.length > 0 &&
            (criteriaRRMM.S11_RRMM_NOTA == 0 ||
              criteriaRRMM.S11_RRMM_NOTA == null) &&
            softErrList.s11TecTerminate == false &&
            softErrList.s11TalTerminate == false &&
            softErrList.s11ElrTerminate == false &&
            softErrList.s11LinTerminate == false &&
            softErrList.s11CarTerminate == false &&
            softErrList.s11AbeTerminate == false) ||
          ((criteriaRRMM.S11_RRMM_NOTA == 0 ||
            criteriaRRMM.S11_RRMM_NOTA == null) &&
            softErrList.s11TecTerminate == false &&
            softErrList.s11TalTerminate == false &&
            softErrList.s11ElrTerminate == false &&
            softErrList.s11LinTerminate == false &&
            softErrList.s11CarTerminate == false &&
            softErrList.s11AbeTerminate == false) ||
          (criteriaRRMM.S11_RRMM_NOTA == 1 &&
                (cohortTreatmentModelListt.length > 0 && cohortTreatmentModelListt.filter(c => c.ndmm === true).length >= 1)  &&
            softErrList.s11Terminate == true) ||
            ((cohortTreatmentModelListt.length > 0 && cohortTreatmentModelListt.filter(c => c.ndmm === true).length >= 1) &&
            (criteriaRRMM.S11_RRMM_NOTA == 0 ||
              criteriaRRMM.S11_RRMM_NOTA == null) &&
            (softErrList.s11TecTerminate == true ||
              softErrList.s11TalTerminate == true ||
              softErrList.s11ElrTerminate == true ||
              softErrList.s11LinTerminate == true ||
              softErrList.s11CarTerminate == true ||
              softErrList.s11AbeTerminate == true))
            ? true
            : false,
        isValidated: validate,
        IsAdmin: IsAdmin(),
      };
        setIsEligible(eligibilityCriteriademographicModel.metInclusionCriteria);

        setCriteriaRRMM((prev) => ({
            ...prev,
            metInclusionCriteria: eligibilityCriteriademographicModel.metInclusionCriteria,
        }));
      if (pid > 0) {
        const response = await EligibilityCriteriaService.SaveRRMMCriteria(
          eligibilityCriteriademographicModel
        );

        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else {
            throw response.error;
          }
        } else {
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
          await LoadData();
            ToggleLoader(false);
            if (response?.data?.showDateChangeAffectAlert) {
                ShowAlertBox();
            }
          if (eligibilityCriteriademographicModel.metInclusionCriteria == true)
            navigatePaths.nextPage = "/ExclusionCriteria";
        }
      }
      return true;
      // }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
    };


    /* <summary>
      date: 24-08-2024
      Name: BV
      description: Show an alert message when other page(index date) could be affected
      <summary>*/
    const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
            "Alert", //title
            CommonError_Msgs.DateChangeAlert, //message
            "Ok", //Confirm button text
            true
        );
    };


  const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {
    function DateCalc(date) {
      //const maxDays = 6575;
      //const daysDifference = calculateDaysDifference(
      //  criteriaRRMM.s4_birth_date,
      //  date
      //);
      //if (daysDifference < maxDays) {
        let IndexDateYear = (new Date(date).getFullYear());
        let BirthYear = (new Date(criteriaRRMM.s4_birth_date).getFullYear());
        let totalYears = IndexDateYear - parseInt(BirthYear);
        if (totalYears < 18) {
        return true;
      }
    }

    switch (name) {
      case "S11_TECVAYLI_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_TECVAYLI_RD: 1,
        }));
        setTecyali_s11(false);

        var S11TecIneligible = false;

        //const dateValidTec = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidTec) {
        //  S11TecIneligible = false;

        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_TECVAYLI_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_TECVAYLI_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11TecIneligible = true;
          } else if (checkEligibileCohortTreatment(date, "2022/10/25")) {
            S11TecIneligible = true;
          } else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11TecIneligible = true;
          }
       // }
        setSoftErrList((prev) => ({
          ...prev,
          s11TecTerminate: S11TecIneligible,
        }));

        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
          s11TecInvalidDate:false,
        }));

        break;

      case "S11_TALVEYTM_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_TALVEYTM_RD: 1,
        }));

        setTalvey_s11(false);
        var S11TalIneligible = false;

        //const dateValidTal = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidTal) {
        //  S11TalIneligible = false;

        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_TALVEYTM_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_TALVEYTM_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11TalIneligible = true;
          } else if (checkEligibileCohortTreatment(date, "2023/08/09")) {
            S11TalIneligible = true;
          } else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11TalIneligible = true;
          }
        //}

        setSoftErrList((prev) => ({
          ...prev,
          s11TalTerminate: S11TalIneligible,
        }));
        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
            s11TalInvalidDate:false,
        }));

        break;
      case "S11_ELREXFIOTM_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_ELREXFIOTM_RD: 1,
        }));

        setElrexfio_s11(false);
        var S11ELRIneligible = false;

        //const dateValidElre = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidElre) {
        //  S11ELRIneligible = false;

        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_ELREXFIOTM_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_ELREXFIOTM_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11ELRIneligible = true;
          } else if (checkEligibileCohortTreatment(date, "2023/08/14")) {
            S11ELRIneligible = true;
          } else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11ELRIneligible = true;
          }
      //  }

        setSoftErrList((prev) => ({
          ...prev,
          s11ElrTerminate: S11ELRIneligible,
        }));
        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
            s11ElrInvalidDate:false,
        }));

        break;
      case "S11_Linvoseltamab_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_Linvoseltamab_RD: 1,
        }));

        setLinvoseltamab_s11(false);
        var S11LinIneligible = false;

        //const dateValidLinv = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidLinv) {
        //  S11LinIneligible = false;

        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_Linvoseltamab_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_Linvoseltamab_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11LinIneligible = true;
          }

          //FDA Approval Date Validation
          //else if (checkEligibileCohortTreatment(date, surveyDate)) {
          //  S11LinIneligible = true;
          //}

          else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11LinIneligible = true;
          }
        //}

        setSoftErrList((prev) => ({
          ...prev,
          s11LinTerminate: S11LinIneligible,
        }));
        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
            s11LinInvalidDate:false,
        }));

        break;
      case "S11_CARVYKTI_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_CARVYKTI_RD: 1,
        }));

        setCarvykti_s11(false);
        var S11CarIneligible = false;

        //const dateValidCarv = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidCarv) {
        //  S11CarIneligible = false;
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_CARVYKTI_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_CARVYKTI_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11CarIneligible = true;
          } else if (checkEligibileCohortTreatment(date, "2022/02/28")) {
            S11CarIneligible = true;
          } else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11CarIneligible = true;
          }
        //}

        setSoftErrList((prev) => ({
          ...prev,
          s11CarTerminate: S11CarIneligible,
        }));
        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
            s11CarInvalidDate:false,
        }));

        break;
      case "S11_ABECMA_Date":
        setCriteriaRRMM((prev) => ({
          ...prev,
          [name]: date,
          S11_RRMM_ABECMA_RD: 1,
        }));

        setAbecma_s11(false);
        var S11AbeIneligible = false;

        //const dateValidAbec = CompareEndofFollowUpDate(
        //  date,
        //  criteriaRRMM.endofFollowUpDate,
        //  criteriaRRMM.lastPatientEncounter
        //);

        //if (!dateValidAbec) {
        //  S11AbeIneligible = false;

        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_ABECMA_Date_ValidError: true,
        //  }));
        //} else {
        //  setCriteriaRRMM((prev) => ({
        //    ...prev,
        //    S11_ABECMA_Date_ValidError: false,
        //  }));

          if (DateCalc(date)) {
            S11AbeIneligible = true;
          } else if (checkEligibileCohortTreatment(date, "2021/03/26")) {
            S11AbeIneligible = true;
          } else if (
            criteriaRRMM.initiationDate1LTreatment != "" &&
            criteriaRRMM.initiationDate1LTreatment != null &&
            checkCARTDate(date, criteriaRRMM.initiationDate1LTreatment)
          ) {
            S11AbeIneligible = true;
          }
       // }      
        setSoftErrList((prev) => ({
          ...prev,
          s11AbeTerminate: S11AbeIneligible,
        }));
        setHardErrList((prev) => ({
          ...prev,
            s11EmptyDate: false,
            s11AbeInvalidDate:false,
        }));

        break;

      default:
        break;
    }
  };

  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div>
          <SideBarMenu activePageId={SideMenuItems.RRMMCriteria} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

                  <div className="col-pad-left eligibiltylocked" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>RRMM CRITERIA</h4>
              </div>
              {!showTopErrMsg &&
                pid != 0 &&
                criteriaRRMM.metInclusionCriteria != null &&
                criteriaRRMM.metInclusionCriteria == false && (
                  <div className="clean-error">
                    <p>{EligibilityCriteria_Msgs.IneligiblePatient}</p>
                  </div>
                )}

              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
                          <div className="survey-question-section" disabled={criteriaRRMM.eligibilityDisabled}>
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S11.</span>
                        <span className="quest-text-pad">
                          <span> Which, if any, of the following</span>{" "}
                          <span
                            className="modal-popup"
                            data-tooltip-id="encounterTip"
                            onClick={() => setShowHideHyperlinkModalS11(true)}
                          >
                            bispecific or CAR-T therapies
                          </span>
                          {showHideHyperlinkModalS11 && (
                            <InfoPopUp
                              description={hyperlinkTextS11}
                              OkClick={() =>
                                setShowHideHyperlinkModalS11(false)
                              }
                            />
                          )}{" "}
                          did this patient receive for the treatment of MM <b>at
                                                      your medical center</b> <u><b>in any subsequent lines of therapy
                                                      (any line except 1L)</b></u>?
                          <br/>
                                                  Please note that:
                                                  <br />
                                                  <br />
                           <ul>
                                                      <li>
                           
                                                          For Bispecifics, patients <u><b>do not have</b></u> to
                          have completed the entire line of therapy to be
                          eligible (i.e., the treatment regimen can be ongoing
                          by the time of data abstraction or last visit); for
                                                          CAR-T, patients <u><b>are required</b></u> to have received the
                          CAR-T infusion.
                                                      </li>
                            <br/>
                                                      <li>
                                                          A therapy <u><b>would not qualify</b></u> if the patient received any part of the therapy as part of a clinical trial or expanded access program (EAP).
                            </li>
                           </ul>
                           
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_TECVAYLI"
                              name="S11_RRMM_TECVAYLI"
                              checked={criteriaRRMM.S11_RRMM_TECVAYLI}
                              onChange={HandleFieldChange}
                            />
                            <label
                              htmlFor="S11_RRMM_TECVAYLI"
                              className="radio-text-padding"
                            >
                              TECVAYLI® (teclistamab-cqyv)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_TECVAYLI && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_TECVAYLI_RD"
                                    name="S11_RRMM_TECVAYLI_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_TECVAYLI_RD === 1
                                    }
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_TECVAYLI_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={tecvayli_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_TECVAYLI_Date}*/}
                                    {/*                                  />*/}
                                      <DatePickerComponent
                                      ctrlId={"S11_TECVAYLI_Date"}
                                     HandleDateChange={HandleDateChange}
                                      minDate={dayjs("01-01-1920")}
                                       maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={criteriaRRMM.S11_TECVAYLI_Date}
                                       />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_TECVAYLI_RD_Unk"
                                    name="S11_RRMM_TECVAYLI_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_TECVAYLI_RD === 99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_TECVAYLI_RD_Unk">
                                    <span className="radio-text-padding">
                                     Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11TecTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />
                              <ErrorField
                                show={criteriaRRMM.S11_TECVAYLI_Date_ValidError}
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11TecInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_TALVEYTM"
                              name="S11_RRMM_TALVEYTM"
                              checked={criteriaRRMM.S11_RRMM_TALVEYTM}
                              onChange={HandleFieldChange}
                            />
                            <label
                              htmlFor="S11_RRMM_TALVEYTM"
                              className="radio-text-padding"
                            >
                              TALVEY<sup className="supscript">TM</sup>{" "}
                              (talquetamab-tgvs)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_TALVEYTM && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_TALVEYTM_RD"
                                    name="S11_RRMM_TALVEYTM_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_TALVEYTM_RD === 1
                                    }
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_TALVEYTM_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={talvey_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_TALVEYTM_Date}*/}
                                    {/*                                  />*/}
                                    <DatePickerComponent
                                     ctrlId={"S11_TALVEYTM_Date"}
                                   HandleDateChange={HandleDateChange}
                                   minDate={dayjs("01-01-1920")}
                                   maxDate={dayjs()}
                                   locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                   date={criteriaRRMM.S11_TALVEYTM_Date}
                                   />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_TALVEYTM_RD_Unk"
                                    name="S11_RRMM_TALVEYTM_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_TALVEYTM_RD === 99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_TALVEYTM_RD_Unk">
                                    <span className="radio-text-padding">
                                      Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11TalTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />
                              <ErrorField
                                show={criteriaRRMM.S11_TALVEYTM_Date_ValidError}
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11TalInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_ELREXFIOTM"
                              name="S11_RRMM_ELREXFIOTM"
                              checked={criteriaRRMM.S11_RRMM_ELREXFIOTM}
                              onChange={HandleFieldChange}
                            />
                            <label
                              htmlFor="S11_RRMM_ELREXFIOTM"
                              className="radio-text-padding"
                            >
                              ELREXFIO<sup className="supscript">TM</sup>{" "}
                              (elranatamab-bcmm)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_ELREXFIOTM && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_ELREXFIOTM_RD"
                                    name="S11_RRMM_ELREXFIOTM_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 1
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_ELREXFIOTM_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={elrexfio_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_ELREXFIOTM_Date}*/}
                                    {/*                                  />*/}
                                     <DatePickerComponent
                                     ctrlId={"S11_ELREXFIOTM_Date"}
                                     HandleDateChange={HandleDateChange}
                                     minDate={dayjs("01-01-1920")}
                                     maxDate={dayjs()}
                                     locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                     date={criteriaRRMM.S11_ELREXFIOTM_Date}
                                     />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_ELREXFIOTM_RD_Unk"
                                    name="S11_RRMM_ELREXFIOTM_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_ELREXFIOTM_RD === 99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_ELREXFIOTM_RD_Unk">
                                    <span className="radio-text-padding">
                                     Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11ElrTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />

                              <ErrorField
                                show={
                                  criteriaRRMM.S11_ELREXFIOTM_Date_ValidError
                                }
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11ElrInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_Linvoseltamab"
                              name="S11_RRMM_Linvoseltamab"
                              checked={criteriaRRMM.S11_RRMM_Linvoseltamab}
                              onChange={HandleFieldChange}
                            />

                            <label
                              htmlFor="S11_RRMM_Linvoseltamab"
                              className="radio-text-padding"
                            >
                              Linvoseltamab (BCMAxCD3)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_Linvoseltamab && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_Linvoseltamab_RD"
                                    name="S11_RRMM_Linvoseltamab_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_Linvoseltamab_RD ===
                                      1
                                    }
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_Linvoseltamab_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={linvoseltamab_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_Linvoseltamab_Date}*/}
                                    {/*                                  />*/}
                                    <DatePickerComponent
                                     ctrlId={"S11_Linvoseltamab_Date"}
                                     HandleDateChange={HandleDateChange}
                                     minDate={dayjs("01-01-1920")}
                                     maxDate={dayjs()}
                                     locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                     date={criteriaRRMM.S11_Linvoseltamab_Date}
                                     />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_Linvoseltamab_RD_Unk"
                                    name="S11_RRMM_Linvoseltamab_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_Linvoseltamab_RD ===
                                      99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_Linvoseltamab_RD_Unk">
                                    <span className="radio-text-padding">
                                      Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11LinTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />
                              <ErrorField
                                show={
                                  criteriaRRMM.S11_Linvoseltamab_Date_ValidError
                                }
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11LinInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_CARVYKTI"
                              name="S11_RRMM_CARVYKTI"
                              checked={criteriaRRMM.S11_RRMM_CARVYKTI}
                              onChange={HandleFieldChange}
                            />

                            <label
                              htmlFor="S11_RRMM_CARVYKTI"
                              className="radio-text-padding"
                            >
                              CARVYKTI® (ciltacabtagene autoleucel)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_CARVYKTI && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_CARVYKTI_RD"
                                    name="S11_RRMM_CARVYKTI_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_CARVYKTI_RD === 1
                                    }
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_CARVYKTI_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={carvykti_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_CARVYKTI_Date}*/}
                                    {/*                                  />*/}
                                     <DatePickerComponent
                                      ctrlId={"S11_CARVYKTI_Date"}
                                      HandleDateChange={HandleDateChange}
                                      minDate={dayjs("01-01-1920")}
                                      maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={criteriaRRMM.S11_CARVYKTI_Date}
                                      />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_CARVYKTI_RD_Unk"
                                    name="S11_RRMM_CARVYKTI_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_CARVYKTI_RD === 99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_CARVYKTI_RD_Unk">
                                    <span className="radio-text-padding">
                                     Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11CarTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />
                              <ErrorField
                                show={criteriaRRMM.S11_CARVYKTI_Date_ValidError}
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11CarInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>
                        <div className="">
                          <div className="long-text-option">
                            <input
                              type="checkbox"
                              id="S11_RRMM_ABECMA"
                              name="S11_RRMM_ABECMA"
                              checked={criteriaRRMM.S11_RRMM_ABECMA}
                              onChange={HandleFieldChange}
                            />

                            <label
                              htmlFor="S11_RRMM_ABECMA"
                              className="radio-text-padding"
                            >
                              ABECMA® (idecabtagene vicleucel)
                            </label>
                          </div>
                          {criteriaRRMM.S11_RRMM_ABECMA && (
                            <div
                              className="dependent-sub-section"
                              style={{
                                backgroundColor: "#F6F6F6",
                                marginLeft: "28px",
                                marginBlock: "6px",
                                padding: "20px 28px",
                              }}
                            >
                              <div className="">
                                <div className="option-without-input">
                                  <label>S12. Date of treatment initiation</label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="S11_RRMM_ABECMA_RD"
                                    name="S11_RRMM_ABECMA_RD"
                                    value={1}
                                    checked={
                                      criteriaRRMM.S11_RRMM_ABECMA_RD === 1
                                    }
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    {/*<DateControl*/}
                                    {/*  ctrlId={"S11_ABECMA_Date"}*/}
                                    {/*  HandleDateChange={HandleDateChange}*/}
                                    {/*  defDate={abecma_s11}*/}
                                    {/*  optionId={1}*/}
                                    {/*  date={criteriaRRMM.S11_ABECMA_Date}*/}
                                                                      {/*/>*/}
                                     <DatePickerComponent
                                     ctrlId={"S11_ABECMA_Date"}
                                     HandleDateChange={HandleDateChange}
                                     minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={criteriaRRMM.S11_ABECMA_Date}
                                    />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id="S11_RRMM_ABECMA_RD_Unk"
                                    name="S11_RRMM_ABECMA_RD"
                                    value={99}
                                    checked={
                                      criteriaRRMM.S11_RRMM_ABECMA_RD === 99
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="S11_RRMM_ABECMA_RD_Unk">
                                    <span className="radio-text-padding">
                                      Date of initiation unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.s11AbeTerminate}
                                message={EligibilityCriteria_Msgs.INELIGIBLE}
                              />
                              <ErrorField
                                show={criteriaRRMM.S11_ABECMA_Date_ValidError}
                                message={
                                  EligibilityCriteria_Msgs.EndOfFollowUpValidationMsg
                                }
                                                          />
                                                          <ErrorField
                                                              show={hardErrList.s11AbeInvalidDate}
                                                              message={CommonError_Msgs.InvalidDate}
                                                          />
                            </div>
                          )}
                        </div>

                        <div className="">
                          <input
                            type="radio"
                            id="S11_RRMM_NOTA"
                            name="S11_RRMM_NOTA"
                            value={1}
                            checked={criteriaRRMM.S11_RRMM_NOTA === 1}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="S11_RRMM_NOTA">
                            <span className="radio-text-padding">
                              None of the above treatments{" "}
                            </span>
                          </label>
                        </div>
                        <ErrorField
                          show={hardErrList.s11Empty}
                          message={EligibilityCriteria_Msgs.S11}
                        />
                        <ErrorField
                          show={hardErrList.s11EmptyDate}
                          message={EligibilityCriteria_Msgs.S11Date}
                        />
                        <ErrorField
                          show={softErrList.s11Terminate}
                          message={EligibilityCriteria_Msgs.INELIGIBLE}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RRMMCriteria;
