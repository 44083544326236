import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const CenterService = {
    GetAllCenters,
    GetCentersExceptAdminSite,
    SaveCenter,
    DeleteCenter,
    GetPatientsCenterSummary,

    GetCenterInfo,
    SaveCenterInfo,

    GetRoundData,
    SaveRoundData,
    GetAllRounds,
    AddNewRound,
    DeleteRound,
    SaveCenterNameAndActiveRound,
    GetCohortTreatmentAndTargets,
    AllPatientsSubmittedInCurrentRound,
    UnlockCenter,
    GetAllCentersAndRounds,
    GetPatientCohortData
};

function GetAllCenters() {
    return WebServiceWrapper.Get(`/center/GetAllCenters`);
}
function GetCentersExceptAdminSite() {
    return WebServiceWrapper.Get(`/center/GetCentersExceptAdminSite`);
}
function SaveCenter(payload) {
    return WebServiceWrapper.Post("/center/SaveCenter", payload);
}
function DeleteCenter(centerId) {
    return WebServiceWrapper.Get(`/center/DeleteCenter?centerId=${centerId}`);
}
function GetPatientsCenterSummary(roleId, centerId) {
    return WebServiceWrapper.Get(`/center/GetPatientSummary?roleId=${roleId}&centerId=${centerId}`);
}

function GetCenterInfo(centerId) {
    return WebServiceWrapper.Get(`/center/GetCenterInfo?centerId=${centerId}`);
}
function SaveCenterInfo(payload) {
    return WebServiceWrapper.Post("/center/SaveCenterInfo", payload);
}
function GetRoundData(roundId, centerRoundId = 0, patientId = 0) {
    return WebServiceWrapper.Get(`/center/GetRoundData?roundId=${roundId}&centerRoundId=${centerRoundId}&patientId=${patientId}`);
}
function SaveRoundData(payload) {
    return WebServiceWrapper.Post("/center/SaveRoundData", payload);
}
function GetAllRounds(centerId) {
    return WebServiceWrapper.Get(`/center/GetAllRounds?centerId=${centerId}`);
}
function AddNewRound(userId, centerId) {
    return WebServiceWrapper.Get(`/center/AddNewRound?userId=${userId}&centerId=${centerId}`);
}
function DeleteRound(centerId, roundId) {
    return WebServiceWrapper.Get(`/center/DeleteRound?centerId=${centerId}&roundId=${roundId}`);
}
function SaveCenterNameAndActiveRound(payload) {
    return WebServiceWrapper.Post("/center/SaveCenterNameAndActiveRound", payload);
}
function GetCohortTreatmentAndTargets(roundId, centerRoundId = 0, patientId = 0, centerId = 0, userId = 0) {
    return WebServiceWrapper.Get(`/center/GetCohortTreatmentAndTargets?roundId=${roundId}&centerRoundId=${centerRoundId}&patientId=${patientId}&centerId=${centerId}&userId=${userId}&isAdmin=${IsAdmin()}`);
}
function AllPatientsSubmittedInCurrentRound(centerId) {
    return WebServiceWrapper.Get(`/center/AllPatientsSubmittedInCurrentRound?centerId=${centerId}`);
}
function UnlockCenter(payload) {
  return WebServiceWrapper.Post("/center/UnlockCenter", payload);
}
function GetAllCentersAndRounds() {
  return WebServiceWrapper.Get("/center/GetAllCentersAndRounds");
}
function GetPatientCohortData(centerId, roundId) {
    return WebServiceWrapper.Get(`/center/GetPatientCohortData?centerId=${centerId}&roundId=${roundId}`);
}