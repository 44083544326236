export const UserLoginMessage = {
  LogginFailure: "Login Failed. Incorrect Email and Password.",
  LogginSuccess: "User logged in",
  LoginEmailBlank: "Enter Email Address",
  LoginPasswordBlank: "Enter Password",
  LoginInvalidEmail: "Enter valid Email",
  InactiveAbstractor: `Your account is inactive. Please contact the administrator at <u><a href="mailto:emmpower@analysisgroup.com">emmpower@analysisgroup.com</a></u> for further assistance.`,
};
export const Abstractor_Msgs = {
  FIRSTNAME: "Enter first name",
  LASTNAME: "Enter last name",
  EMAIL_REQUIRED: "Enter email address",
  EMAIL_VALID: "Please enter a valid email",
  CENTER: "Please select center",
  REQUIRED: "This field is required",
  PASSWORD_VALID:
    "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
  EMAIL_EXISTS: "Email address already exists",
  CENTER_LOAD_ERROR: "Error occurred while loading centers",
  ADD_SUCCESS: "Abstractor added successfully",
  EDIT_SUCCESS: "Abstractor updated successfully",
  DELETE_SUCCESS: "Abstractor deleted successfully",
  DELETE_FAIL: "Error occurred when deleting abstractor",
  EMAIL_SENDOUT_SUCCESS: "Email sent successfully", //"An email has been sent to the email address regarding the further process",
  EMAIL_SENDOUT_ERROR: "Email address does not exists",
  STATUS_CHANGE_CONFIRM: "Are you sure you want to change the status of this abstractor?",
  STATUS_CHANGE_SUCCESS: "Status updated successfully",
  STATUS_CHANGE_FAIL: "Failed to update status",
};
export const Center_Msgs = {
  NAME_EMPTY: "Please enter the center name",
  NAME_EXISTS: "The center name already exists",
  ADD_SUCCESS: "Center added successfully",
  EDIT_SUCCESS: "Center updated successfully",
  DELETE_SUCCESS: "Center deleted successfully",
  DELETE_FAIL: "Error occurred when deleting center",
  CENTER_NOT_LOCKED: "Please fill out the Center Information form and then add patients.",
  CENTER_UNLOCK_CONFIRM: "Would you like to unlock the center?",
  UnlockSuccessMsg: "Center Unlocked successfully",
  CENTER_LOCKED: " Center information is Submitted and Locked",
  QUOTA_ALL_ZERO: "The patient cannot be added as the Target number of new charts is either not specified or is set to zero.",
  QUOTA_MET: "The patient cannot be added because the Target number of new charts for this cohort has already been reached.",
};
export const CenterInfo_Msgs = {
  C1: "Please choose one of the provided options",
  C1Txt: "Please specify the practice type",
  C2: "Please choose at least one of the provided options",
  C3: "Please select the US state or District of Columbia (DC)",
  C4: "Please choose one of the provided options",
  C5: "Please choose one of the provided options",
  C6: "Please enter a value between 0 and 20,000 (no decimals permitted) or otherwise specify “Unknown”",
  // C6_not_in_range: "Enter a value between 1 and 20,000.",
  C7: "Please enter a value between 0 and 20,000 (no decimals permitted) or otherwise specify “Unknown”",
  // C7_not_in_range: "Enter a value between 1 and 20,000.",
  C8: "Please enter a value between 0 and 200,000 (no decimals permitted) or otherwise specify “Unknown”",
  // C8_not_in_range: "Enter a value between 1 and 200,000.",
  C9: "Please enter a value between 0 and 40,000 (no decimals permitted) or otherwise specify “Unknown”",
  // C9_not_in_range: "Enter a value between 1 and 40,000.",
  Confirm: "Please confirm.",
  C10Txt: "Please describe the timing of use, the treatment, and dosing for this treatment",
};
export const Round_Msgs = {
  //NAME: "Enter center name",
  TARGET_CHARTS_EMPTY:
    "Please enter the target number of charts for all treatment cohorts.",
  ADD_SUCCESS: "Round added successfully",
  EDIT_SUCCESS: "Round updated successfully",
  ADD_FAIL: "Error occurred when adding round",
  //DELETE_SUCCESS: "Round deleted successfully",
  //DELETE_FAIL: "Error occurred when deleting round",
  TARGET_CHARTS_MINIMUM: "The count is below the required quota of {min} for {tx}. Please revise",
  NEXT_ROUND_NOT_ALLOWED: "A total of {count} patients are yet to complete the form. Once the form has been submitted by every patient from the current round, you can proceed to the next one."
};
export const ResetPasswordMessages = {
  PASSWORD_BLANK: "Enter Password",
  CONFIRM_PASSWORD_BLANK: "Re-Enter Password",
  PASSWORD_MISMATCH: "Passwords did not match!",
  PASSWORD_INVALID:
      "Your password should include at least one lowercase letter, one uppercase letter, one number, and one symbol, with a minimum length of eight characters.",
  LINK_EXPIRED: "Link has expired",
  EMAIL_NOTEXISTS: "Email address does not exists",
  //100:"Some error!!! "
};
export const CommonError_Msgs = {
  LOAD_ERR: "Error occurred while loading.",
  SAVE_ERR: "Error occurred while saving.",
  REQUIRED: "This field is required.",
  Hard_Empty:
    "Please ensure that all mandatory fields are filled out before proceeding.",
  Hard_ValidationFail:
        "Please check the fields with errors and ensure all dates meet the required conditions to proceed.",
    DateChangeAlert: "The dates modified could impact the validation in the subsequent pages. Please validate the pages highlighted in red.",
      InvalidDate:"Invalid Date. Please enter a valid date and ensure that the date is not in the future."
};
export const ForgotPassword = {
  EmailNotExistent:
    "This Email Address has not been set up - Please contact your Administrator to set this up.",
  EmailSuccess: "An Email has been sent to you regarding the further process.",
  ErrorOccurred: "Error occurred.",
};
export const CommonMsgs = {
  DeleteConfirmMsg: "Are you sure you want to delete this data?",
  SessionTimeout: "Your session has expired, you will be logged out now.",
  SubmitSuccess: "Patient Submitted Successfully",
};
export const PatientDashboard_Msgs = {
  ExportSuccess: "Data exported successfully",
  UnlockConfirmMsg:
    "Would you like to unlock and allow the abstractors to edit this patient?",
  UnlockSuccessMsg: "Patient Unlocked successfully",
  AbstractorNotesHeader: "Important notes to abstractors:",
  AbstractorNotesPtA:
    "a) If the patient died on or before the time of the previous round of data abstraction or has not had any new follow-up visits since the time of the previous round of data abstraction, the patient is not qualified for a chart update. ",
  AbstractorNotesPtB:
    " b) Patients are required to not receive any part of the cohort-defining line of therapy as part of a clinical trial. For some patients, the cohort-defining line of therapy could still be ongoing at the time of the previous round of data abstraction. If the patient received any subsequent parts of the cohort-defining line of therapy s part of a clinical trial from the time of the last round of data abstraction to the time of the current round of data abstraction, please do not update this patient's chart. Instead, this patient's record will be censored at the time of the last round of data abstraction.",
  UnlockEligibilityConfirmMsg:
    "Would you like to unlock and allow the abstractors to edit the Eligibility Criteria section of this patient?",
  DownloadSuccess: "Downloaded successfully",
  ExportAllAlertMsg: "To export all patient data, please ensure at least one patient is added."
};
export const demographicScreen_Msgs = {
  Q1: "Please choose one of the provided options",
  Q2: "Please choose at least one of the provided options or “Unknown”",
  Q3: "Please choose one of the provided options",
  Q4: "Please select the US state or District of Columbia (DC)",
  Q5: "Please choose at least one of the provided options, “No insurance”, or “Unknown”",
  Q6: "Please choose one of the provided options",
  Q7: "Please choose one of the provided options",
  Q8: "Please choose one of the provided options",
  Q9: "Please choose one of the provided options",
  Q9a: "Please enter a value between 0 and 300 (up to 1 decimal permitted) or otherwise specify “Unknown”",
  Q10a: "Please enter the date of assessment (if known) or “Unknown” otherwise",
  Q10bw:
    "Please enter a value between the range specified or otherwise specify “Unknown”",
  Q10bKgw:
    "Please enter a value between 1 and 630 (up to 2 decimal permitted) or otherwise specify “Unknown”",
  Q10bLbsw:
    "Please enter a value between 1 and 1400 (up to 2 decimal permitted) or otherwise specify “Unknown”",
  Q10bh:
    "Please enter a value between the range specified or otherwise specify “Unknown”",
  Q10bFtIh:
    "Please enter a value for Feet between 1 and 9, Inches between 0 to 11 (no decimal permitted) or otherwise specify “Unknown”",
  Q10bCmh:
    "Please enter a value between 1 and 300 (no decimal permitted) or otherwise specify “Unknown”",
  Q10d: "This assessment does not fall within 1 year on or before the index date. Please enter the assessment closest to the index date within this 1-year window",
};

export const NDMM_Criteria_Msgs = {
  title:
    "Patients will still be considered as having received a line of treatment if they received all therapy drugs except for dexamethasone or when dexamethasone has been replaced by prednisone or prednisolone.",
};

export const EligibilityCriteria_Msgs = {
  EligiblePatient:
    "This patient chart meets the study eligibility criteria. Please proceed to the next section of the chart abstraction form.",
  IneligiblePatient:
    "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected. Click on next arrow to proceed with entering a new patient chart.",
  INELIGIBLE:
    "This patient does not meet the eligibility criteria for this treatment cohort.",
  CohortIneligible:
    "This patient does not meet the eligibility criteria for this treatment cohort.",
  CrossWalkPtA:
    "Now you should have selected a patient for eligibility screening. This patient’s ID for the eMMpower study is [PatientId]. ",
  CrossWalkPtB:
    "Please save in your site's secured system a crosswalk between this patient's ID for eMMpower ([PatientId]) and the patient's ID in your site's secured system. ",
  CrossWalkPtC:
    "This crosswalk is critical and will be used in subsequent rounds of data abstraction to update this patient's charts. By answering 'Yes', you confirm that the crosswalk has been saved in your site's secured system, linking this patient's ID for eMMpower with the patient's ID at your site.",
  INVALID:
    "Patient must be at least 18 years of age at the date of Epcoritamab initiation.",
  DateCriteria1:
    "The date of treatment initiation with epcoritamab is before the date of LBCL diagnosis. Please revise your response.",
  DateCriteria2:
    "The date of treatment initiation with epcoritamab is on or before 05/19/2023. Please revise your response.",

  EndOfFollowUpValidationMsg: "Date of treatment initiation should be less or equal to end of follow up date",

  QS1: " Please specify if patient receive treatment with epcoritamab.",
  QS2: "Please select the date of initiation of treatment with epcoritamab.",
  QS3: "Please select the diagnosis of large B-cell lymphoma (LBCL).",
  QS4: "Please select the subtype of LBCL with which the patient is diagnosed.",
  QS5: "Please select the date when the patient was first diagnosed with LBCL.",
  QS6: "Please select the year of birth.",
  Radio: "Please choose one of the provided options",
  S3Date:
    "Please enter the date of initial MM diagnosis (if known) or “Unknown” otherwise ",
  S4Date: "Please enter the date of birth (if known) or “Unknown” otherwise",
  S4DOBValidate: "Please enter date of birth less than or equal to date of initial MM diagnosis",
  InvalidDate:"Invalid Date",
  S5: "Please choose one of the provided options",
  S6: "Please choose one of the provided options",
  S6Date:
    "Please enter the date of 1L treatment initiation (if known) or “Unknown” otherwise ",
    S7: "Please choose one of the provided options",
    S51: "Please choose one of the provided options",
  S8: "Please choose one of the provided options",
  S9: "Please choose one of the provided options",
  S10: "Please choose at least one of the provided options",
  S11: "Please choose at least one of the provided options or “None of the above treatments",
  S11Date:
    "Please enter the date of treatment initiation (if known) or “Unknown” otherwise ",
  s13: "Please choose one of the provided options",
  s14: "Please choose one of the provided options",
  s15: "Please choose one of the provided options",
    s16: "Please choose at least one of the provided options",
    EligibiltyLockConfirm: "You are about to lock the eligibility responses. Once locked, they cannot be changed.",
    EligibiltyLockConfirm1:"Select 'OK' to confirm and proceed, or 'Cancel' to go back."

};

export const DiseaseHistErrorMsg = {
  ProvideOptions: "Please choose one of the provided options",
  DateFirstErrMsg:
    "Please enter the date of first radiotherapy (if known) or “Unknown” otherwise ",
  DateLastErrMsg:
    "Please enter the date of last radiotherapy (if known) or “Unknown” otherwise ",
  DateFirstValidMsg: "The date of of first radiotherapy entered is invalid; please enter a  first radiotherapy that is on or within 3 months prior initiation date for CAR-T treatment.",
  DateLastValidMsg: "The date of of last radiotherapy entered is invalid; please enter a  last radiotherapy that is on or within 3 months prior initiation date for CAR-T treatment.",

  RangeErrMsg:
    "Please enter a value between 0 and 100 (up to 1 decimal permitted) or otherwise specify “Unknown”",
};

export const ComborbidityErrorMsg = {
  q18EmptyHard:
    "Please choose at least one of the provided options or None of the above",
  pulmonaryEmptyHard:
    "Please choose one of the options listed under chronic pulmonary diseases",
  KidneyEmptyHard:
    "Please choose one of the options listed under chronic kidney disease ",
  neuropathyEmptyHard:
    "Please choose one of the options listed under neuropathy",
  malignancyEmptyHard:
    "Please choose one of the options listed under malignancy",
  cardiovascularEmptyHard: "Please choose one of the options listed under cardiovascular disease",
};

export const TTCharacteristics_MSGS = {
  //TECHVAYLI
  // administrationEmpty: "Choose one of the options for Date of dose of administration",
  // dosingphaseEmpty: "Choose dosing phase",
  // siteofcareEmpty: "Choose site of care",
  // admissionEmpty: "Choose Date of  impatient admission",
  // dischargeEmpty: "Choose Date of  impatient discharge",
  DecimalRange:"Please enter a value between 0.01 and 4000 (up to 2 decimals permitted)",
  General:"Please choose one of the provided options",
  name_dose_steroidstalHardEmpty:"Please choose at least one of the provided options or Unknown ", 
  steroids_tal_txtEmpty:"Please describe the total dose strength",
  steroids_tech_txtEmpty:"Please describe the total dose strength",
  name_dose_steroidsHardEmpty:"Please choose at least one of the provided options or Unknown ",
  administrationEmpty: "Please choose one of the provided options",
  dosingphaseEmpty: "Please choose one of the provided options",
  siteofcareEmpty: "Please choose one of the provided options",
  admissionEmpty: "Please choose one of the provided options",
  dischargeEmpty: "Please choose one of the provided options",
  administrationEmptyDate:
    "Please enter the date of dose administration (if known) or unknown ",
  admissionEmptyDate:
    "Please enter the date of admission (if known) or unknown ",
  dischargeEmptyDate:
    "Please enter the date of discharge (if known) or “Unknown” ",
  admissionDateCheck:
    "Please select date of admission which is not less than date of discharge",
  dischargeDateCheck:
    "Please Select date of discharge which is greater than date of admission",
  indexDateRRMMNotMatchingTech:
    "Please Select at least 1 date of dose administration(Tecvayli) = Bispecifics/CAR-T index date ",

    indexDateRRMMNotMatchingTal:
    "Please Select at least 1 date of dose administration(Talvey) = Bispecifics/CAR-T index date ",

    Q39techSteroidsEmptyHard:"Please choose at least one of the provided options or Unknown",
  Q39TechEmptyHard: "Please choose one of the provided options",
  Q39techReasonHard: "Please choose at least one of the provided options or Unknown",
  Q39techGranduloEmptyHard:
    "Please choose at least one of the provided options or “Unknown”",

  Q39TalEmptyHard: "Please choose one of the provided options",
  Q39talReasonHard: "Please choose at least one of the provided options",
  Q39talSteroidsEmptyHard:
    "Please choose at least one of the provided options or Unknown",

  Q38TechEmptyHard: "Please choose one of the provided options",
  Q38DateEmpty:
    "Please enter the date of dose frequency reduction (if known) or unknown otherwise",
  Q38TechDateInvalid: "At least one of the dates of dose frequency reduction is invalid. Please ensure that all dates of dose frequency reduction are after the treatment initiation date and on or before the date of last dose administration for the treatment cohort.",

  Q38TalEmptyHard: "Please choose one of the provided options",
  Q38TalDateEmpty:
    "Please enter the date of dose frequency reduction (if known) or unknown otherwise",
  Q38TalDateInvalid: "At least one of the dates of dose frequency reduction is invalid. Please ensure that all dates of dose frequency reduction are after the treatment initiation date and on or before the date of last dose administration for the treatment cohort.",

  specifyOtherText:"Please specify other text",
  specifyDosageFrequency:"Please describe the dosing strength",
  //TALVEY
  administrationEmpty_tal: "Please choose one of the provided options",
  dosingphaseEmpty_tal: "Please choose one of the provided options",
  siteofcareEmpty_tal: "Please choose one of the provided options",
  admissionEmpty_tal: "Please choose one of the provided options",
  dischargeEmpty_tal: "Please choose one of the provided options",

  //37
  q37TalveyEmpty: "Please choose one of the provided options",
  q37TechvayliEmpty: "Please choose one of the provided options",

  //40
  Q40DosingFreqEmptyHard: "Please choose one of the provided options",
  Q40DosingFreqTxtEmptyHard: "(please enter the dosing frequency)",
  Q40IgGEmptyHard: "Please choose one of the provided options",
  Q40Init_dtHard:
    "Please enter the initiation date of IVIG (if known) or unknown otherwise",
  Q40Init_RdHard: "Please choose one of the provided options",
  Q40ReasonEmptyHard: "Please choose at least one of the provided options",
  
  // Q36DateGreaterThanPrevious: "Date of dose administration should be greater than the previous dose",
  //Q36.1
  Q36Point1ReasonTxtEmpty: "Please enter the hospitalization reason",
  //36 n 36.1
  Q36DateOutOfBound: "Date should be between index date and end of follow up"
};

export const NDMMTreatmentCohort_Msgs = {
  q21: "Please choose one of the provided options",
  q23: "Please fill data for Consolidation therapy",
  q24: "Please fill data for Maintenance therapy",
  q22_25a: "Please choose one of the provided options",
  q22_25aOth: "Please enter the other agent",
  q22_25b:
    "Please enter the date of first administration (if known) or unknown otherwise",
  q22_25b_idx:
    "The date of first administration and/or last administration are invalid; please ensure that: (i) at least one date of first administration is the same as the date of 1L NDMM treatment initiation",
  q22_25b_sf:
    "Date of first administration is on or before the end of follow up date",
  q22_25b_se:
    "Date of last administration is on or before the first administration date",
  q22_25c:
    "Please enter the date of last administration (if known) or unknown otherwise",
  q22_25c_ef:
    "Date of last administration is on or before the end of follow up date",
  q22_25c_sef:
    "The date of first administration and/or last administration are invalid; please ensure that: (i) for each agent, the date of first administration is on or before the date of last administration, and (ii) Please ensure that the date of last administration is on or before the end of follow-up date for the treatment cohort.",
  q22_25c_sef_p2:
    "The date of first administration and/or last administration are invalid; please ensure that: (i) for each agent, the date of first administration is on or before the date of last administration, and (ii) All dates of first and last administrations of induction agents are on or before the earliest first administration of all consolidation agents and are on or before the patient’s date of death (if applicable) and end of 1L NDMM line of therapy.",
  q22_25c_sef_p3:
    "The date of first administration and/or last administration are invalid; please ensure that: (i) for each agent, the date of first administration is on or before the date of last administration, and (ii) All dates of first and last administrations of induction and consolidation agents are on or before the earliest first administration of all maintenance agents and are on or before the patient’s date of death (if applicable) and end of 1L NDMM line of therapy.",
  q22_25d:
    "Please enter a value between 1 and 4000 or otherwise specify “Unknown”",
  q22_25dTxt: "Please enter dosing schedule",
  q22_25e: "Please choose one of the provided options",
  q22_25f: "Please choose at least one of the provided options or “Unknown”",
  q22_25fa: "Please enter the reason for discontinuing",
  qDuplicateAgent:
        "This option is already provided in the dropdown menu. Please select the option there",
    q22_25g:
        "Please enter a value between 0.01 and 4000 (up to 2 decimals permitted)",
};

export const BispecificCartTreatmentCohort_Msgs = {
  q26b: "Please enter the line of therapy between 2 and 40",
  q26d: "Please enter the date of last administration (if known) or unknown otherwise",
  q26d_sef:
    "The date of last administration is invalid; please ensure that the date of last administration is on or after the date of first administration for the treatment cohort.",
  q26e: "Please enter a value between 1 and 4000 or otherwise specify “Unknown”",
  q26eTxt: "Please enter dosing schedule",
  q26f: "Please choose one of the provided options",
  q26g: "Please choose at least one of the provided options or “Unknown”",
  q26ga: "Please enter the reason for discontinuing",
  q26d_lef:
    "Please ensure that the date of last administration is on or before the end of follow- up date  for the treatment cohort",
  q26d_ef:
        "Please ensure that the date of first administration is on or before the end of follow- up date  for the treatment cohort",
    q26duplicateb: "Repeated line of therapy values cannot be accepted between regimens. Please enter a different line of therapy.",
    q26h: "Please enter a value between 0.01 and 4000 (up to 2 decimals permitted)",
};

export const CarTCharMsgs = {
  q41_Date_Empty: "Please enter the date of apheresis. This should be on or before the initiation date for CAR-T treatment. ",
  q41_Date_NotValid: "The date of apheresis entered is invalid; please enter a date of apheresis that is on or before the initiation date for CAR-T treatment. "
  , carTEmpty_Msg: "Please choose one of the provided options ",
  q42_Date_Not_Entered_Bridge:"Please enter date of therapy initiation; this should be on or after the date of apheresis and on or before both the lymphodepletion therapy date (if applicable) and the initiation date for CAR-T treatment. ",
  q42_Date_Not_Valid_Bridge:"The date of therapy initiation entered is invalid; please enter a date of therapy initiation that is on or after the date of apheresis and on or before both the lymphodepletion therapy date (if applicable) and the initiation date for CAR-T treatment.",

  q42_Date_Not_Entered_Lympo: "Please enter date of therapy initiation; this should be on or after both the date of apheresis and bridging therapy date (if applicable) and on or before the initiation date for CAR-T treatment. ",
  q42_Date_Not_Valid_Lympo:"The date of therapy initiation entered is invalid; please enter a date of therapy initiation that is on or after both the date of apheresis and bridging therapy date (if applicable) and on or before the initiation date for CAR-T treatment",
  q42_Date_Empty:"Please enter date of therapy initiation;this should be on or after both the date of apheresis and bridging therapy date (if appliacble) and on or before the intiation date for CAR-T treatment.",
  q43_Radio_Empty: "Please choose one of the provided options ",
  q43_Date_Empty: "Please enter the date of procedure; this should be on or after the initiation date for CAR-T treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  q43_Date_Not_Valid: "The date of procedure entered is invalid; please enter a date of procedure that is on or after the initiation date for CAR-T treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter. "
}

export const hyperlinkTextQ21 = `
<div>
  <p style="text-align: left;">If the induction, consolidation, and maintenance therapies are not documented on the chart, the following definitions may be considered to infer:</p>
  <ul>
    <li style="text-align: left;"><u>Induction therapy</u>: defined as the initial 1L regimen received before transplant.</li>
    <li style="text-align: left;"><u>Consolidation therapy</u>: defined as the same regimen as the induction therapy used <b><u>after</u></b> transplant but <b><u>before</u></b> initiation of maintenance therapy.</li>
    <li style="text-align: left;"><u>Maintenance therapy</u>: defined as a regimen used after transplant and after consolidation therapy (if consolidation therapy is used). The following algorithm may be referenced by sites if needed. Specifically, a maintenance therapy can be any of the following:</li>
  </ul>
  <ol style="margin-left: 30px;">
    <li style="text-align: left;">If a regimen is followed by SCT*, then regimens (excluding CAR-T) initiated within 90 days after SCT and with duration ≤ 4 cycles (≤112 days) will be considered as MTs and will not advance the line.</li>
    <li style="text-align: left;">If a regimen lasting at least 4 cycles (≥112 days) is followed by a regimen within 90 days, and all drugs (excluding corticosteroids) in the subsequent regimen were used within the prior regimen, then the regimen will be considered as MT, and the line will not advance.<br/>
    If the main regimen is followed by SCT*, the allowed gap between regimens is extended to 183 days.</li>
    <li style="text-align: left;">Within a regimen, if an individual medication is discontinued, it will not advance the line.</li>
  </ol>
  <p style="margin-left: 40px; text-align: left;">* Regimen followed by SCT is defined as the SCT occurred after the last service date of any drug within the regimen (excluding corticosteroids), and the SCT occurred before (regimen end date + 90 days).</p>
</div>
`;

export const hyperlinkTextS5 = `
<div>
  <p style="text-align: left;">A line of therapy (LOT) can be determined or inferred based on information available in the chart. For sites that do not have LOT information, the following method may be used as reference for defining the 1st LOT, or 1L treatment. Specifically, initiation of 1L therapy is defined as the date on which the first NCCN-recommended medication for MM is given, either after the MM diagnosis date, or up to 14 days before it (corticosteroids do not affect this date).</p>
  <ul>
    <li style="text-align: left;">All NCCN-recommended medications for MM that are initiated within 60 days of the first medication start date should be considered as first-line therapy.</li>
    <li style="text-align: left;">Changes in any agent within 60 days of first medication start date is not considered a line of therapy change.</li>
  </ul>
</div>
`;

export const hyperlinkTextS5Radio = `
<div>
  <p style="text-align: left; font-weight: 500">Patients will still be considered as having received a line of treatment if they received all therapy drugs except for dexamethasone or when dexamethasone has been replaced by prednisone or prednisolone.</p>
</div>
`;

export const hyperlinkTextS7 = `
<div>
  <p style="text-align: left;">
    Transplant eligibility status may be determined based on information contained in the patient chart or physician notes. Transplant eligible (TE) patients include but are not necessarily limited to:
    <br />
    <ul>
    <li style="text-align: left;">
    Any patient who received autologous or allogeneic stem cell transplant (SCT) while on 1L treatment.</li>
    <li style="text-align: left;">
    Any patient who had stem cells collected and stored after initiating and while on 1L treatment.</li>
    </ul>
  </p>
</div>
`;

export const hyperlinkTextS9 = `
<div>
  <p style="text-align: left;">Systemic treatments exclude steroids, one-time monotherapy of cyclophosphamide, local radiotherapy to

stabilize bone lesions, etc</p>
</div>
`;

export const hyperlinkTextS11 = `
<div>
  <p style="text-align: left;">Patients will be considered if they received treatments as monotherapy or as combined therapy and in any line of treatment (except 1L) on or after the FDA approval date of each treatment. For those treated with CAR-T, patients are required to have received CAR-T infusion; patients who only received apheresis but did not receive infusion should not be considered eligible for the CAR-T cohorts</p>
</div>
`;

export const hyperlinkTextAEReporting = `
<div>
  <p style="text-align: left;">
    If applicable, please report safety information for this patient according to the study protocol Section 5 at 
    <a href="http://www.janssensafety.com" target="_blank" rel="noopener noreferrer">
      www.janssensafety.com
    </a>.
  </p>
</div>
`;

export const hyperlinkTextQ19 = `
<div>
  <p style="text-align: left;">
    This can be a visit, telehealth consultation, lab test, imaging report, or any other recorded date in the patient chart prior to or on the date you began abstraction for this chart.
  </p>
</div>
`;

export const hyperlinkTextQ36Tecvayli = `
<div>
  <p style="text-align: left; font-weight: normal;">
    Step-up and weekly dosing for <b>TECVAYLI® (teclistamab-cqyv)</b> and <b>TALVEY<sup>TM</sup> (talquetamab-tgvs)</b> should be based on the recommended dosing schedule according to the prescription label (dosing information below up-to-date as of May 13th, 2024):
  </p>
  <table border="1" style="width:100%; border-collapse: collapse; table-layout: fixed;">
    <tr>
      <td colspan="4" style="border: 1px solid black; text-align: center;">TECVAYLI® Recommended Dosing Schedule<b></td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: center;">Dosing Schedule</td>
      <td style="border: 1px solid black; text-align: center;">Day</td>
      <td colspan="2" style="border: 1px solid black; text-align: center;">Dose</td>
    </tr>
  <tr>
    <td rowspan="3" style="border: 1px solid black; text-align: center;">Step-up dosing schedule</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 1</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 1 </td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.06 mg/kg </td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 4 </td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 2 </td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.30 mg/kg </td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 7 </td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">1<sup>st</sup> treatment dose</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">1.50 mg/kg </td>
  </tr>
 
    <tr>
      <td style="border: 1px solid black; text-align: center;">Weekly dosing schedule </td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">One week after first treatment dose and weekly thereafter</td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">Subsequent treatment doses</td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">1.50 mg/kg once weekly</td>

    </tr>
    
  </table>

    <p style="text-align: left; font-size: 12px; font-weight: normal;">
    <sup>a</sup>Based on actual body weight.<br/>
    <sup>b</sup>Dose may be administered between 2-4 days after the previous dose and may be given up to 7 days after the previous dose to allow for resolution of adverse reactions<br/>
    <sup>c</sup>Maintain a minimum of 6 days between weekly doses<br/>
    <sup>d</sup>Dose may be administered between 2 to 7 days after step-up dose 3.<br/>
    <sup>e</sup>Maintain a minimum of 12 days between biweekly (every 2 weeks) doses.<br/>
  </p>
</div>
`;

export const hyperlinkTextQ36Talvey = `
<div>
  <p style="text-align: left; font-weight: normal;">
    Step-up and weekly dosing for <b>TECVAYLI® (teclistamab-cqyv)</b> and <b>TALVEY<sup>TM</sup> (talquetamab-tgvs)</b> should be based on the recommended dosing schedule according to the prescription label (dosing information below up-to-date as of May 13th, 2024):
  </p>
  <table border="1" style="width:100%; border-collapse: collapse; table-layout: fixed;">
    <tr>
      <td colspan="4" style="border: 1px solid black; text-align: center;">TALVEY<sup>TM</sup> Recommended Weekly (Every 1 Week) Dosing Schedule</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: center;">Dosing Schedule</td>
      <td style="border: 1px solid black; text-align: center;">Day</td>
      <td colspan="2" style="border: 1px solid black; text-align: center;">Dose<sup>a</sup></td>
    </tr>
  <tr>
    <td rowspan="3" style="border: 1px solid black; text-align: center; ">Step-up dosing schedule</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 1</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 1</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.01 mg/kg</td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 4<sup>b</sup></td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 2</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.06 mg/kg</td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 7<sup>b</sup></td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">1<sup>st</sup> treatment dose</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.40 mg/kg</td>
  </tr>
 
    <tr>
      <td style="border: 1px solid black; text-align: center;">Weekly dosing schedule </td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">One week after first treatment dose and weekly thereafter<sup>c</sup></td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">Subsequent treatment doses</td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.40 mg/kg once weekly</td>

    </tr>
    
  </table>

  <table border="1" style="width:100%; border-collapse: collapse; table-layout: fixed;">
    <tr>
      <td colspan="4" style="border: 1px solid black; text-align: center;">TALVEY<sup>TM</sup> Recommended Biweekly (Every 2 Weeks) Dosing Schedule </td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: center;">Dosing Schedule</td>
      <td style="border: 1px solid black; text-align: center;">Day</td>
      <td colspan="2" style="border: 1px solid black; text-align: center;">Dose<sup>a</sup></td>
    </tr>
  <tr>
    <td rowspan="4" style="border: 1px solid black; text-align: center;">Step-up dosing schedule</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 1</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 1</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.01 mg/kg</td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 4<sup>b</sup></td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 2</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.06 mg/kg</td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 7<sup>b</sup></td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Step-up dose 3</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.40 mg/kg</td>
  </tr>
  <tr>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">Day 10<sup>d</sup></td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">1<sup>st</sup> treatment dose</td>
    <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.80 mg/kg</td>
  </tr>
 
    <tr>
      <td style="border: 1px solid black; text-align: center;">Weekly dosing schedule </td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">One week after first treatment dose and weekly thereafter<sup>e</sup></td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">Subsequent treatment doses</td>
      <td style="border: 1px solid black; text-align: center; font-weight: normal;">0.80 mg/kg every 2 weeks</td>

    </tr>
    
  </table>


  <p style="text-align: left; font-size: 12px; font-weight: normal;">
    <sup>a</sup>Based on actual body weight.<br/>
    <sup>b</sup>Dose may be administered between 2-4 days after the previous dose and may be given up to 7 days after the previous dose to allow for resolution of adverse reactions<br/>
    <sup>c</sup>Maintain a minimum of 6 days between weekly doses<br/>
    <sup>d</sup>Dose may be administered between 2 to 7 days after step-up dose 3.<br/>
    <sup>e</sup>Maintain a minimum of 12 days between biweekly (every 2 weeks) doses.<br/>
  </p>
</div>
`;

export const hyperlinkTextStandardIMWGReportingCriteria = `
<div>
  <p style="text-align: left; font-weight: normal;">
    <b>Standard IMWG Reporting Criteria</b>
  </p>
  <table border="1" style="width:100%; border-collapse: collapse; table-layout: fixed;">
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px;"><b>Response</b></td>
      <td style="border: 1px solid black; text-align: left; padding: 10px;"><b>Definition</b></td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Stringent complete response (sCR)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Complete response as defined below plus normal free light chains (FLC) ratio and absence of clonal cells in bone marrow biopsy by immunohistochemistry (κ/λ ratio ≤ 4:1 or ≥ 1:2 for κ and λ patients respectively, after counting ≥ 100 plasma cells)</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Complete response (CR)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Negative immunofixation on the serum and urine and disappearance of any soft tissue plasmacytomas and &lt; 5% plasma cells in bone marrow aspirates</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Very good partial response (VGPR)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Serum and urine M-protein detectable by immunofixation but not on electrophoresis or ≥ 90% reduction in serum M-protein plus urine M-protein level &lt; 100 mg per 24 h</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Partial response (PR)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">≥ 50% reduction of serum M-protein plus reduction in 24-h urinary M-protein by ≥ 90% or to &lt; 200mg per 24h. If the serum and urine M-protein are unmeasurable, a ≥ 50% decrease in the difference between involved and uninvolved FLC levels is required in place of the M-protein criteria. If serum and urine M-protein are unmeasurable, and serum-free light assay is also unmeasurable, ≥ 50% reduction in plasma cells is required in place of M-protein, provided baseline bone marrow plasma-cell percentage was ≥ 30%. In addition to these criteria, if present at baseline, a ≥ 50% reduction in the size (sum of the products of the maximal perpendicular diameters [SPD] of measured lesions) of soft tissue plasmacytomas is also required.</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Minimal response (MR)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">≥ 25% but ≤ 49% reduction of serum M-protein and reduction in 24-h urine M-protein by 50%-89%. In addition to the above listed criteria, if present at baseline, a 25%-49% reduction in SPD of soft tissue plasmacytomas is also required.</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Stable disease (StD)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Stability of disease is described by providing the time-to-progression estimates. Not meeting criteria for complete response, very good partial response, partial response, minimal response, or progressive disease.</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Progressive disease (PD)</td>
      <td style="border: 1px solid black; text-align: left; padding: 10px; font-weight: normal;">Any one or more of the following criteria:
        <ul style="list-style-type: disc; padding-left: 20px;">
          <li style="text-align: left; font-weight: normal;">
            Increase of 25% from lowest confirmed response value on or more of the following criteria:
            <ul style="list-style-type: circle; padding-left: 20px;">
              <li style="text-align: left; font-weight: normal;">Serum M-protein (absolute increase must be ≥ 0.5g/dL)</li>
              <li style="text-align: left; font-weight: normal;">Serum M-protein (increase ≥ 1 g/dL, if the lowest M component was ≥ 5 g/dL)</li>
              <li style="text-align: left; font-weight: normal;">Urine M-protein (absolute increase must be ≥ 200 mg/24 h)</li>
            </ul>
          </li>
          <li style="text-align: left; font-weight: normal;">In patients without measurable serum and urine M-protein levels, the difference between involved and uninvolved FLC levels (absolute increase must be > 10 mg/dL)</li>
          <li style="text-align: left; font-weight: normal;">In patients without measurable serum and urine M-protein levels and without measurable involved FLC levels, bone marrow plasma-cell percentage irrespective of baseline status (absolute increase must be ≥ 10%)</li>
          <li style="text-align: left; font-weight: normal;">Appearance of a new lesion(s), ≥ 50% increase from nadir in SPD of >1 lesion, or ≥ 50% in the longest diameter of a previous lesion > 1 cm in short axis</li>
          <li style="text-align: left; font-weight: normal;">≥ 50% increase in circulating plasma cells (minimum of 200 cells per μL) if this is the only measure of disease</li>
        </ul>
      </td>
    </tr>
  </table>

  <p style="font-weight: normal; text-align: left">
    FLC: Free light chain; IMWG: International Myeloma Working Group.
  </p>
</div>
`;

export const hyperlinkTextLineOfTherapy = `
<div>
  <p style="text-align: left;" font-weight: normal;">
    <b>Line of Therapy Algorithm<b>
  </p>
  <p style="text-align: left; font-weight: normal;">
    A line of therapy (LOT) can be determined or inferred based on information available in the chart. For sites that do not have LOT information, the following method may be used as reference.
  </p>
  <p style="text-align: left; font-weight: normal;">
    <b>Defining Regimens and LOTs</b>
  </p>
  <p style="text-align: left; font-weight: normal;">
    Each regimen will be defined by a start date, an end date, and a distinct regimen, made up of one or more drugs. A patient may receive multiple treatment regimens in sequence during the course of their treatment.  
  </p>
  <p style="text-align: left; font-weight: normal;">
    <b>Regimen:</b> A regimen is formed by one or several NCCN-recommended medications for MM (see Table below for MM Drugs) identified from  both structured and unstructured EMR during the first 60 days following the regimen initiation.  
  </p>
  <p style="text-align: left; font-weight: normal;">
  Each regimen will end on either the discontinuation date of all drugs within the regimen (except for corticosteroids), or the date of treatment change (including augmentation and switching), whichever happens first. The date of treatment change will be the initiation date of the next line of therapy regimen as defined below. Changes in any agent (‘targeted’ or chemo) within 60 days of first treatment is not considered a line change.
  </p>
  <p style="text-align: left; font-weight: normal;">
  Corticosteroids do not affect LOT increments, start dates, or end dates. A corticosteroid will be considered part of a regimen if there is at least 1 day overlap with the regimen’s first 60 days.
  </p>
  <p style="text-align: left; font-weight: normal;">
  If a regimen is discontinued and then resumed within 90 days, then this will be treated as the same regimen.  
  </p>
  <p style="text-align: left; font-weight: normal;">
  <b>Gap:</b> Gap is calculated as the days elapsed between the last day of current dispensing/administration and its subsequent dispensing/administration.  
  </p>
  <p style="text-align: left; font-weight: normal;">
  <b>Discontinuation of individual medication:</b> Discontinuation of an individual medication will be defined as the failure to refill the medication of interest within the maximum allowed gap (MAG) of 90 days after the expiration  the last day of dispensing/administration. For belantamab, MAG is 183 days instead of 90 days due to adverse event management. The discontinuation date of the medication will be calculated as the last day of dispensing/administration.  
  </p>
  <p style="text-align: left; font-weight: normal;">
  If a stem cell transplant (SCT) occurred between two drug segments, then they will be treated as the same drug segment if all of the following criteria are met: (1) the SCT occurred on or after the last service date within the prior drug segment, (2) the SCT occurred on or before the end of the MAG after the prior drug segment, and (3) the next drug segment began within 6 months (183 days) after the SCT.  
  </p>
  <p style="text-align: left; font-weight: normal;">
  Melphalan and cyclophosphamide drug segments with duration <28 days will be excluded as preparation for SCT, so as not to advance the line.
  </p>
  <p style="text-align: left; font-weight: normal;">
  <b>Augmentation:</b> An augmentation will be defined as receiving a new medication of interest at least 60 days prior to discontinuing any of the medications included in the current regimen. The date initiating the new medication will be considered as the augmentation date. Changing between daratumumab and daratumumab/hyaluronidase does not count as augmentation.
  </p>
  <p style="text-align: left; font-weight: normal;">
  <b>Switch:</b> A switch will be defined as receiving a new medication of interest with at least one medication of the current regimen discontinued prior to or within 60 days after the initiation of the new medication. The initiation date of the new medication will be considered as the switch date. Changing between daratumumab and daratumumab/hyaluronidase does not count as a switch.
  </p>
  <p style="text-align: left; font-weight: normal;">
  <b>Maintenance therapies:</b> Three types of maintenance therapies (MTs) are allowed.
  </p>
 
  <ol style="list-style-type: none; text-align: left; font-weight: normal;">
    <li>(1) If a regimen is followed by SCT*, then regimens (excluding CAR-T) initiated within 90 days after SCT and with a duration ≤ 4 cycles (≤112 days) will be considered as MTs and will not advance the line.</li>
    <li>(2) If a regimen lasting at least 4 cycles (≥112 days) is followed by a regimen within 90 days, and all drugs (excluding corticosteroids) in the subsequent regimen were used within the prior regimen, then the regimen will be considered as MT, and the line will not advance. If the main regimen is followed by SCT*, the allowed gap between regimens is extended to 183 days.</li>
    <li>(3) Within a regimen, if an individual medication is discontinued, it will not advance the line.</li>
  </ol>
 
  <p style="text-align: left; font-weight: normal;">
  * Regimen followed by SCT is defined as the SCT occurred after the last service date of any drug within the regimen (excluding corticosteroids), and the SCT occurred before (regimen end date + 90 days).
  </p>
 
  <p style="text-align: left; font-weight: normal;">
  <b>Chemotherapy:</b> The addition of chemotherapy agents (see Table below for MM Drugs) initiates a new regimen.
  </p>
 
  <p style="text-align: left; font-weight: normal;">
  <b>Discontinuation of a regimen:</b> Discontinuation of a regimen is defined as discontinuation of all medications in the regimen (excluding corticosteroids), or treatment change (including augmentation and switching), whichever occurs first. For example, if no treatment change happens, discontinuation of the regimen will be the latest discontinuation date of all non-corticosteroid medications included in the regimen. If only a corticosteroid remains after discontinuation of other therapies in the regimen, that will be considered a regimen discontinuation (corticosteroids do not extend the regimen end date). Whereas if a treatment change occurs prior to the latest discontinuation date, the date of treatment change will be considered as the discontinuation date of the regimen.
  </p>
 
  <p style="text-align: left; font-weight: normal;">
  There is an exception that if a patient used a maintenance therapy (see definition above) within 6 months after a SCT, regardless of whether the MT drugs were part of the initial regimen or not, such “switching” will NOT be considered as switching and thus will NOT trigger LOT increase. The discontinuation date for the regimen will be extended to the discontinuation of the MT.
  </p>
 
  <p style="text-align: left; font-weight: normal;">
  <b>CAR-T LOT:</b> For CAR-T patients, therapy between apheresis and CAR-T infusion is considered bridging therapy. If a LOT starts on or after apheresis and CAR-T infusion occurs >60 days later, the LOTs will be combined to include both bridging and CAR-T infusion.  
  </p>
 
  <p style="text-align: left; font-weight: normal;">
  <b>Line of Therapy Increase:</b> Induction therapy preceding SCT is a new LOT, however SCT itself is not a new LOT. Multiple SCTs are permitted with minimum 6-month gap between SCTs per patient. A treatment change (including augmentation and switching) or initiation of a new regimen after discontinuation of a previous regimen will increase the LOT (with the exception of maintenance therapy and CAR-T LOT, as described above).  
  </p>
 
 
  <p style="text-align: left;" font-weight: normal;">
    MM Drugs: 
  </p>
 
 
     



  <table border="1" style="width:100%; border-collapse: collapse; table-layout: fixed;" class="line-of-therapy">
    <tr>
        <td style="border: 1px solid black; text-align: center;">Drug Class</td>
        <td style="border: 1px solid black; text-align: center;">Drug Name</td>
        <td style="border: 1px solid black; text-align: center;">Drug Molecule</td>
        <td style="border: 1px solid black; text-align: center;">ROA</td>
        <td style="border: 1px solid black; text-align: center;">Imputed Days Supply*</td>
    </tr>
 
    <tr>
        <td rowspan="3" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
            Immunomodulatory (iMiD)
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Thalidomide (Synovir, Thalomid)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">28 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Lenalidomide (Revlimid)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">28 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Pomalidomide (Pomalyst)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">28 days</td>
    </tr>
 
    <tr>
        <td rowspan="3" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
            Proteasome Inhibitor (PI)
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Bortezomib (Velcade)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">SQ, IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Carfilzomib (Kyprolis)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">7 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Ixazomib (Ninlaro)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">28 days</td>
    </tr>
 
    <tr>
        <td rowspan="3" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
            Anti-CD38 Monoclonal Antibody (MAB)
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Daratumumab (Darzalex)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Biologics</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Daratumumab (Darzalex Faspro)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Biologics</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">SQ</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Isatuximab (Sarclisa)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Biologics</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">28 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
          Other Monoclonal Antibody (MAB)
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Elotuzumab (Empliciti)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">Biologics</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align: left">14 days</td>
    </tr>
 
 
    <tr>
        <td style="border: 1px solid black; text-align: center; font-weight: normal;">Histone Deacetylase (HDAC) Inhibitor</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Panobinostat (Farydak)</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Small Molecule </td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Oral</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">21 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; text-align: center; font-weight: normal;">Nuclear Export Inhibitor (INE)</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word; ">Selinexor (Xpovio)</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word; ">Small Molecule </td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word; ">Oral</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word; ">7 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; text-align: center; font-weight: normal;">B-cell Lymphoma 2 <br/> (BCL-2) Inhibitor</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Venetoclax (Venclexta)</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Small Molecule </td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">Oral</td>
        <td style="border: 1px solid black; text-align: left; font-weight: normal; break-word;">1 day</td>
    </tr>
 
 
    <tr>
        <td rowspan="8" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
          Chemotherapy (Chemo) 
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Cyclophosphamide (Cytoxan)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV, Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Melphalan (Alkeran)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV, Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Doxorubicin (Adriamycin, Doxil)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">21 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Etoposide (Toposar, VePesid)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV, Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">5 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Cisplatin (Platinol)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">5 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Bendamustine (Treanda, Belrapzo, Bendeka, generic)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">28 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Cytarabine/Pf</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Vincristine (rarely used)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV, Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">7 days</td>
    </tr>
 
 
    <tr>
        <td rowspan="3" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
        Corticosteroid
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Prednisone</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">1 day</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Prednisolone</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">1 day</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Dexamethasone</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Small Molecule</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV, Oral</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">1 day</td>
    </tr>
 
    <tr>
        <td rowspan="6" style="border: 1px solid black; word-wrap: break-word; font-weight: normal;">
          B-cell maturation antigen (BCMA) targeted therapy
        </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Belantamab mafodotin-blmf (Blenrep)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Biologics</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">21 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Idecabtagene vicleucel (Abecma) </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Car-T Cell Therapy </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">1 day</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Ciltacabtagene autoleucel (Carvykti)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Car-T Cell Therapy</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">IV</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">1 day</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Teclistamab (Tecvayli)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Biologics </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">SQ</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">7 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Elranatamab (Elrexfio)</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Biologics </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">SQ</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">14 days</td>
    </tr>
 
    <tr>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Linvoseltamab</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">Biologics </td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">SQ</td>
        <td style="border: 1px solid black; font-weight: normal; word-wrap: break-word; text-align:left">TBD</td>
    </tr>
 
 
    <tr>
        <td style="border: 1px solid black; text-align: center; font-weight: normal;">
        G protein–coupled receptor, class C, group 5, member D (GPRC5D) targeted therapy 
        </td>
        <td style="border: 1px solid black; text-align: center; font-weight: normal; text-align:left">Talquetamab (Talvey)</td>
        <td style="border: 1px solid black; text-align: center; font-weight: normal; text-align:left">Biologics</td>
        <td style="border: 1px solid black; text-align: center; font-weight: normal; text-align:left">SQ</td>
        <td style="border: 1px solid black; text-align: center; font-weight: normal; text-align:left">14 days</td>
    </tr> 
</table>
 
 
 
</div>
`;
export const predefinedReasons = [
  {
    agentid: null,
    reasonid: 1,
    reasonName: "Patient death",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 2,
    reasonName: "Adverse event",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 3,
    reasonName: "Disease progression",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 4,
    reasonName: "Insufficient response without progression",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 5,
    reasonName: "Cost or insufficient insurance coverage",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 6,
    reasonName: "End of treatment plan",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 7,
    reasonName: "Achieved CR or better (per the 2016 IMWG criteria)",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 8,
    reasonName: "Achieved (sustained) MRD negativity (10",
    reasonText: null,
    supscripttext: "-5)",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 9,
    reasonName: "Achieved (sustained) MRD negativity (10",
    reasonText: null,
    supscripttext: "-6)",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 10,
    reasonName: "Achieved VGPR or better (per the 2016 IMWG criteria)",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 11,
    reasonName: "Suboptimal response (PR or SD) (per the 2016 IMWG criteria)",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 12,
    reasonName: "Patient's decision (if none of the above)",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 13,
    reasonName: "Physician's decision (if none of the above)",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
  {
    agentid: null,
    reasonid: 14,
    reasonName: "Other, please specify: ",
    reasonText: null,
    supscripttext: "",
    isSelected: false,
    reasonUnk: null,
  },
];
export const NonCohortTreatment_Msgs = {
  //Ndmm
  Q27a: "Please choose one of the provided options",
  Q27b: "Please enter the Start date of LOT (if known) or “Unknown” otherwise",
  // Q27b_LessThanLot3:
  //   "“NDMM 2nd LOT start” should be less than or equal to “NDMM 3rd LOT start”",
  q27b_LessThan2ndLot: "Date should be on or after the start date of the 2nd LOT",
  Q27b_DateBeforeIndex: "Date should be on or after the latter of the index date and date of last administration of all 1L agents (exclude dexamethasone, prednisone, and prednisolone dates of first and last administration)",
  Q27b_LessThanEOF: "Date should be on or before the date of last patient encounter and/or date of death.",
  Q27c: "Please choose one of the provided options",
  Q27d: "Please choose at least one of the provided options or “Unknown”",
  Q27d_txt: "Please enter the name of the prescribed treatment",

  q28_C_before_q27_B_err:
    "Date should be on or after the start date of the 2nd LOT",
  q28_C_beyond_ndmm_3rd_LOT_err:
    "Date should be less than or equal to “NDMM 3rd LOT Start”",
  q28b_InvalidCombo: "“3rd LOT” is specified",

  q29_C_before_q27_B_err:
    "Date should be on or after the start date of the 3rd LOT",

  //Q29 uses Q32 msgs
  Q30: "Please choose at least one of the provided options",
  //Bispecifics
  Q31a: "Please choose one of the provided options",
  Q31b: "Please enter the Start date of LOT (if known) or “Unknown” otherwise",
  q31_B_beyond_eof_err: "Date should be on or before the date of last patient encounter and/or date of death.",
  q31_B_before_index_err: "Date should be on or after the initiation date for this treatment",
  Q31c: "Please choose one of the provided options",
  Q31d: "Please choose at least one of the provided options",
  Q31d_txt: "Please enter the name of the prescribed treatment",

  Q32b: "Please choose one of the provided options",
  Q32c: "Please enter the date of discontinuation of LOT (if known) or “Unknown” otherwise",
  Q32c_LessThanEOF: "Date should be less than or equal to “end of follow up”",
  q32_MoreThanNxtLotStart: "Date should be beyond or equal to “next lot start”",
  Q32d: "Please choose one of the provided options",
  Q32e: "Please choose at least one of the provided options",
  Q32f: "Please choose one of the provided options",
  Q32f_Invalid1:
    "Wrong combination “No” is specified in “Discontinued treatment for this LOT”",
  Q32f_Invalid2:
    "Please revise; patient cannot have 3rd LOT ongoing if this LOT was discontinued.",

  Q33: "Please choose at least one of the provided options or “Unknown”",
};

export const NDMMSCTCharecteristics_Msgs = {
  Q34Empty: "Please choose at least one of the provided options or “Unknown” ",
  Q34Text: "(please enter the name of the prescribed treatment)",
  Q35Empty: "Please choose one of the provided options",
  Q35a: "Please enter the date of SCT transplant (if known) or unknown otherwise",
  Q35aValid:
    "The date of stem cell transplant (SCT) is invalid. Please ensure that the date of SCT falls on or after the date of last administration of all induction therapy agents or the 1L NDMM treatment cohort and on or before the dates of first administration of consolidation and maintenance therapy agents.",
  Q35c: "Please enter the number of stem cells/kg (in millions) between 0 and 100.",
  Q35d: "Please enter the number of stem cells (in millions) between 0 and 100.",
  Q35e: "Please enter the number of mobilization attempts between 0 and 20",
  Q35f: "(please enter the name of the prescribed treatment)",
};

export const CohortDefiningTreatment_Msgs = {
  Q19Empty:
    "Please enter the date of last patient encounter; this should fall on or after the date of treatment initiation and on or before the date of chart abstraction",
  Q19DateValidError:
    "The date of last patient encounter is invalid; please enter a date on or after the date of treatment initiation for all eligible cohorts and on or before the date of chart abstraction.",
  Q20Empty:
    "Please enter the date of death; this should be on or after the date of last patient encounter and on or before the date of chart abstraction.",

  Q20EmptyDate:
    "Please enter the date of death; this should be on or after the date of last patient encounter and on or before the date of chart abstraction.",
  Q20DateValidError:
    "The date of death is invalid; please enter a date on or after the date of last patient encounter and on or before the date of chart abstraction.",
};

export const AdverseEvents_Msgs = {
  RadioNoSelection: "Please choose one of the provided options",
  OnsetDtEmpty: "Please enter the date of onset; this should be on or after the initiation date for this treatment and on or before the end of this line of treatment (plus 30 days), date of death (if applicable), or date of last patient encounter.",
  OnsetDtBeforeIndex: "Date should be on or after the initiation date for this treatment",
  DateBeforeIndex: "Date should be on or after the initiation date for this treatment",
  DtAfterAEEnd: "Date should be on or before the end of this line of treatment (plus 30 days), date of death (if applicable), or date of last patient encounter",
  ResolutionDtEmpty: "Please enter the date of resolution",
  EarlyImpDtEmpty: "Please enter the date of earliest improvement",
  //55
  // NeutroOnsetAfterResolution: "Date should be on or before the date of resolution of this neutropenia event",
  NeutroResolutionBeforeOnset: "Date should be on or after the onset for this neutropenia event",
  // NeutroOnsetBefore: "Date should be on or after both the initiation date for this treatment and onset for this neutropenia event and on or before the end of this line of treatment (plus 30 days), date of death (if applicable), or date of last patient encounter",
  // NeutroOnsetAfter: "Date should should be on or before the date of resolution of this neutropenia event, end of this line of treatment (plus 30 days), date of death (if applicable), or date of last patient encounter",

  //56
  // CytoOnsetAfterResolution: "Date should be on or before the date of resolution of this cytopenia event",
  CytoResolutionBeforeOnset: "Date should be on or after the onset for this cytopenia event",
  //59 
  DysResBeforeEarlyImp: "Date should be on or after the date of earliest improvement of this dysgeusia event",
  //used for early imp n res- cols C, D
  DysResolutionBeforeOnset: "Date should be on or after the date of onset for this dysgeusia event",
  //60
  NailResBeforeEarlyImp: "Date should be on or after the date of earliest improvement of this nail disorder event",
  //used for early imp n res- cols C, D  
  NailResolutionBeforeOnset: "Date should be on or after the date of onset for this nail disorder event",
  //61
  // SkinResBeforeOnset: "Date should be on or after the date of first occurrence of this skin disorder event",
  //used for early imp n res- cols C, D  
  SkinResolutionBeforeOnset: "Date should be on or after the date of onset for this skin disorder event",
  SkinResBeforeEarlyImp: "Date should be on or after the date of earliest improvement of this skin disorder event",
  //63
  // NeuroOnsetAfterResolution: "Date should be on or before the date of resolution for this neurotoxicity event",
  NeuroResolutionBeforeOnset: "Date should be on or after the onset for this neurotoxicity event",



  //55
  NeutroOnsetDtEmpty: "Please enter the Date of neutropenia onset (if known) or “Unknown” otherwise",
  NeutroResDtEmpty: "Please enter the Date of neutropenia resolution (if known) or “Unknown” otherwise",
  //56
  CytoOnsetDtEmpty: "Please enter the Date of cytopenia onset (if known) or “Unknown” otherwise",
  CytoResDtEmpty: "Please enter the Date of cytopenia resolution (if known) or “Unknown” otherwise",
  //59, 60 
  DysOnsetDtEmpty: "Please enter the Date of first occurrence (if known) or “Unknown” otherwise",
  DysEarlyDtEmpty: "Please enter the Earliest date of improvement (if known) or “Unknown” otherwise",
  DysResDtEmpty: "Please enter the Date of resolution (if known) or “Unknown” otherwise",
  //63
  NeuroOnsetDtEmpty: "Please enter the Date of neurotoxicity onset (if known) or “Unknown” otherwise",
  NeuroResDtEmpty: "Please enter the Date of neurotoxicity resolution (if known) or “Unknown” otherwise",
  //57, 63, 59
  ChkBoxRadNoSelection: "Please choose at least one of the provided options or “Unknown”",
  CheckedNoText: "Please enter the Infection organism",
  //59
  q59CheckedNoText: "Please describe the intervention received",
  
  //Date of Onset
  DtSameAsPrevEpisodes: "Date of onset should not be the same as the previous episodes",

  //58
  WtLossResolutionBeforeOnset: "Date should be on or after the date of onset for this weight loss event",
  WtLossBeforeResolution: "Date should be on or after the date of resolution for this weight loss event",
  WtLossStabilizationDtEmpty: "Please enter the date of weight becoming stable",
}

export const TreatmentResponseProgression_Msgs = {
  Q44Date: "Please choose one of the provided options.",
  Q44DateEmpty: "Please enter the date of progression assessment; this should be on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  DateInvalidValidation: "The date of progression assessment entered is invalid; please enter a date of progression assessment that is on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  Q44Reason: "Please choose at least one of the provided options or “Unknown” ",
  Q44Method: "Please choose one of the provided options",

  Q45Treatment: "Please choose one of the provided options.",
  Q45Date: "Please choose one of the provided options.",
  Q45DateEmpty: " Please enter the date of response assessment; this should be on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  Q45DateInvalid: "The date of response assessment entered is invalid; please enter a date of response assessment that is on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  Q45Method: "Please choose one of the provided options.",

  Q46Date: "Please choose one of the provided options.",
  Q46DateEmpty: "Please enter the date of MRD assessment; this should be on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  Q46DateInvalid: "The date of MRD assessment entered is invalid; please enter a date of MRD assessment that is on or after the initiation date for the cohort-defining treatment and on or before the end of this line of therapy, date of death (if applicable) and last patient encounter.",
  Q46Method: "Please choose one of the provided options.",
  Q46Sensitivity: "Please choose one of the provided options.",
  Q46MRDStatus: "Please choose one of the provided options.",

  Q47Date: "Please choose one of the provided options.",
  Q47DateEmpty: "Please enter the date of progression assessment; this should be on or after the initiation date for this 2nd LOT and on or before the end of the 2nd LOT, date of death (if applicable), or date of last patient encounter.",
  Q47Reason: "Please choose at least one of the provided options or “Unknown” ",
  Q47Method: "Please choose one of the provided options.",

  Q48Treatment: "Please choose one of the provided options",
  Q48Date: "Please choose one of the provided options.",
  Q48DateEmpty: "Please enter the date of response assessment; this should be on or after the initiation date for this 2nd LOT and on or before the end of the 2nd LOT, date of death (if applicable), or date of last patient encounter.",
  Q48Method: "Please choose one of the provided options.",

  Q49Date: "Please choose one of the provided options.",
  Q49DateEmpty: " Please enter the date of progression assessment; this should be on or after the initiation date for this 3rd LOT and on or before the end of the 3rd LOT, date of death (if applicable), or date of last patient encounter.",
  Q49Reason: "Please choose at least one of the provided options or “Unknown”  ",
  Q49Method: "Please choose one of the provided options.",

  Q50Treatment: "Please choose one of the provided options",
  Q50Date: "Please choose one of the provided options.",
  Q50DateEmpty: " Please enter the date of response assessment; this should be on or after the initiation date for this 3rd LOT and on or before the end of the 3rd LOT, date of death (if applicable), or date of last patient encounter.",
  Q50Method: "Please choose one of the provided options",

  Q51Date: "Please choose one of the provided options.",
  Q51DateEmpty: "Please enter the date of progression assessment; this should be on or after the initiation date for this subsequent LOT and on or before the end of this subsequent LOT, date of death (if applicable), or date of last patient encounter.",
  Q51Reason: "Please choose at least one of the provided options or “Unknown” ",
  Q51Method: "Please choose one of the provided options",

  Q52Treatment: "Please choose one of the provided options ",
  Q52Date: "Please choose one of the provided options.",
  Q52DateEmpty: " Please enter the date of response assessment; this should be on or after the initiation date for this subsequent LOT and on or before the end of this subsequent LOT, date of death (if applicable), or date of last patient encounter.",
  Q52Method: "Please choose one of the provided options"
};
