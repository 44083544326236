import React, { useContext, useEffect, useRef, useState } from "react";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData } from "../../Helpers/Utilities";
import { useNavigate } from "react-router-dom";
import PatientDetails from "../../Components/PatientInformation";
import { DefaultAGGridProps, ErrorMessages, HTTPResponse, LockType, PatientStatus, Role, ToastMessageType, UrlType } from "../../Helpers/Enums";
import { PatientService } from "../../WebApiServices/Patient.service";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../Contexts/AppContextProvider";
import PatientSummary from "./../PatientSummary";
import { Center_Msgs, CommonError_Msgs, PatientDashboard_Msgs } from "../../Helpers/HelperText";
import ConfirmPopUp from "../../Components/ConfirmPopUp";
import * as XLSX from 'xlsx';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { CenterService } from "../../WebApiServices/Center.service";
import { Dropdown } from "react-bootstrap";
import { Config } from "../../Helpers/Config";
const Dashboard = () => {

  const navigate = useNavigate();
  const [allPatients, setAllPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef("");
  const { ShowToast, ToggleLoader, HandleSessionTimeout, ShowActionPopUp } = useContext(AppContext);
  const [viewSummary, setViewSummary] = useState(false)

  let userDetails = GetLocalStorageData("user")
  userDetails = userDetails && JSON.parse(userDetails);

  const userRole = userDetails?.userRole ?? 0;
  const centerId = userDetails?.centerId ?? 0;
  const userId = userDetails?.userId ?? 0;
  const [isCenterLocked, setIsCenterLocked] = useState(false);
  const isAdmin = userRole === Role.Admin ?? false;

  const [pidToUnlock, setPidToUnlock] = useState();
  const [unlockType, setUnlockType] = useState();
  const [showUnlockConfirm, setShowUnlockConfirm] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Select Status');
  const [selectedStatusId, setSelectedStatusId] = useState(null);

  const [areAllQuotaZero, setAreAllQuotaZero] = useState(true);
  const [isQuotaMet, setIsQuotaMet] = useState(true);

  const iconStyle = {
    width: 25,
    margin: 10,
    cursor: "pointer",
  };
  useEffect(() => {
    RemoveLocalStorageData("patientId");
    //RemoveLocalStorageData("uniqueEncryptedPatientId");
    //RemoveLocalStorageData("surveyDate")
    RemoveLocalStorageData("patient")
    RemoveLocalStorageData("ChartSelection")
    RemoveLocalStorageData("CohortTreatment")
    //centerId = GetLocalStorageData("centerId");
    const FetchData = async () => {
      ToggleLoader(true)

      setStatusDropdown([
        { label: 'All', value: 1 },
        { label: 'Eligible', value: 2 },
        { label: 'Terminated', value: 3 },
        { label: 'Pending', value: 4 },
      ]);
      //Set All by default
      setSelectedStatus('All');
      setSelectedStatusId(PatientStatus.None);

      await GetAllPatients();
      ToggleLoader(false)
    }
    FetchData();
  }, [])
  useEffect(() => {
    setFilteredPatients(allPatients?.filter((a) =>
      a.uniqueEncryptedPatientId?.replace(/\s/g, '').toLowerCase().includes(searchQuery.replace(/\s/g, '').toLowerCase())
      && (
        selectedStatusId === PatientStatus.None ||
        (selectedStatusId === PatientStatus.Eligible && a.patientStatus && a.isEligibilityCriteriaLocked) ||
        (selectedStatusId === PatientStatus.Terminated && a.patientStatus == false) ||
        (selectedStatusId === PatientStatus.Pending && a.patientStatus !== false && !a.isEligibilityCriteriaLocked)
      )
    )
      .sort((a) => a.employeeName));
  }, [searchQuery, allPatients, selectedStatusId])

  const GetAllPatients = async () => {
    try {
      // ToggleLoader(true)
      const response = await PatientService.GetAllPatients(centerId);
      const status = response.status;
      if (status !== HTTPResponse.OK) {

        if (status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else if (status === HTTPResponse.BadRequest)
          throw response.error;
      } else {
        const { data } = response;
        if (data != null) {

          const { patientData, isCenterLocked, areAllQuotaZero, isQuotaMet } = data;

          setAllPatients(patientData);
          setIsCenterLocked(isCenterLocked);
          setAreAllQuotaZero(areAllQuotaZero);
          setIsQuotaMet(isQuotaMet);
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
      ShowToast(ErrorMessages.LOAD_ERROR, ToastMessageType.Failed);
    }
  }
  const HandleSearch = () => {
    setSearchQuery(searchRef.current.value);
  }
  const AddNew = async () => {
    const ShowAlertBox = async (message) => {
      const choice = await ShowActionPopUp(
        "Alert",
        message,
        "Ok",
        true
      );
      return;
    };
    if (isAdmin || isCenterLocked) {
      //New patient not allowed if quota all 0 or met #11770
      if (areAllQuotaZero) {
        await ShowAlertBox(Center_Msgs.QUOTA_ALL_ZERO);
      } else if (isQuotaMet) {
        await ShowAlertBox(Center_Msgs.QUOTA_MET);
      } else {
        const ptArr = {
          canEdit: true
        };
        AddLocalStorageData("patient", JSON.stringify(ptArr));
        return navigate("/PatientScreening");
      }
    }
    else {
      await ShowAlertBox(Center_Msgs.CENTER_NOT_LOCKED);
    }
  };
  const Edit = async (patientID, uniqueEncryptedPatientId, surveyDate, locked, submit, centerId, createdBy, roundId, activeRound, crosswalk, centerRoundId, isEligibilityCriteriaLocked, canEdit) => {


    AddLocalStorageData("patientId", patientID);
    //AddLocalStorageData("uniqueEncryptedPatientId", uniqueEncryptedPatientId);
    //AddLocalStorageData("surveyDate", surveyDate);

    const ptArr = {
      patientId: patientID,
      uniqueEncryptedPatientId: uniqueEncryptedPatientId,
      surveyDate: surveyDate,
      locked: locked,
      submit: submit,
      ptCenterId: centerId,
      abstractorId: createdBy,
      roundId: roundId,
      activeRound: activeRound,
      crosswalk: crosswalk,
      centerRoundId: centerRoundId,
      eligibilityCriteriaLocked: isEligibilityCriteriaLocked,
      canEdit
    };
    AddLocalStorageData("patient", JSON.stringify(ptArr))
    //Set cohort treatments in local storage
    ToggleLoader(true);
    const response = await CenterService.GetCohortTreatmentAndTargets(roundId, centerRoundId, patientID, centerId, userId);//,pid
    ToggleLoader(false);
    if (response?.status === HTTPResponse.OK) {
      const { data } = response;

      if (data != null) {
        const { item1, item2 } = data;//item1-> Chart Selection list, item2-> CohortTreatmentList
        //const chartselection = item1.centerTreatmentRoundList;
        const cohorttreatment = item2;
        //AddLocalStorageData("ChartSelection", JSON.stringify(chartselection))
        AddLocalStorageData("CohortTreatment", JSON.stringify(cohorttreatment))
      }
    }

    if (roundId != activeRound) {
      const choice = await ShowActionPopUp(
        "Alert", //title
        <div>{renderStyledMessage(surveyDate)}</div>,
        "OK", //Confirm button text
        false

      );
      if (!choice) {

        return ;
      } else {
        return navigate("/PatientScreening");
      }
    }
    else {
      return navigate("/PatientScreening");
    }

  };

  const renderStyledMessage = (surveyDate) => {
    return (
      <div>
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          {PatientDashboard_Msgs.AbstractorNotesHeader}
        </span>
        <br /><br />

        <div>
          {PatientDashboard_Msgs.AbstractorNotesPtA.replace("[AbstractionDate]", surveyDate)}
        </div>
        <br />
        <div>
          {PatientDashboard_Msgs.AbstractorNotesPtB}
        </div>
      </div>
    );
  };


  const UnlockClick = (patientId, lockType) => {
    setShowUnlockConfirm(true);
    setPidToUnlock(patientId);
    setUnlockType(lockType);
  }
  //Unlock CONFIRM
  const YesClick = async () => {//patientId

    ToggleLoader(true);
    await UnlockPatient()//(patientId, lockType)
    setShowUnlockConfirm(false)
    ToggleLoader(false);

  }
  const NoClick = () => {
    setShowUnlockConfirm(false)
  }
  const UnlockPatient = async () => {//patientId
    try {
      const payload = {
        pid: pidToUnlock,
        userId,//: userId,
        lockType: unlockType
      }
      //ToggleLoader(true);
      const response = await PatientService.UnlockPatient(payload)
      //ToggleLoader(false);

      if (response?.status === HTTPResponse.Unauthorized) {
        HandleSessionTimeout();
      }
      else if (response?.status === HTTPResponse.OK) {
        await GetAllPatients();
        ShowToast(PatientDashboard_Msgs.UnlockSuccessMsg, ToastMessageType.Success);
      }
      else
        throw response.error;
      //ToggleLoader(false);



    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
    }
  }

  // GRID
  const columnDefs = [
    {
      headerName: "Patient ID",
      field: "uniqueEncryptedPatientId",
      width: 150,
      cellRenderer: (params) => <div
      > {params.data.uniqueEncryptedPatientId} </div>,
    },
    {
      headerName: "Center",
      field: "centerName",
      width: 250,
      //minWidth: 150,
      cellRenderer: (params) => (
        <div>
          {params.data.centerName}
        </div>
      )
    },
    {
      headerName: "Status",
      field: "patientStatus",
      width: 130,
      //minWidth: 150,
      cellRenderer: (params) => (
        <>
          {params.data.patientStatus == false ?
            <div style={{ color: "red" }}>
              Terminated
            </div>
            :
            (params.data.isEligibilityCriteriaLocked ?
              (params.data.patientStatus &&
                <div style={{ color: "green" }}>
                  Eligible
                </div>)
              :
              <div style={{ color: "orange" }}>
                Pending
              </div>)
          }
        </>
      )
    },
    {
      headerName: "Last Edited On (EST)",
      field: "lastEditedOn",
      width: 200,
      cellRenderer: (params) => <div
      > {params.data.lastEditedOn}</div>,
      cellClass: "td",
    },
    // EDIT/VIEW
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (
        <>
          {
              <img
              src={params.data.canEdit ? "../Assets/images/Icon-awesome-edit.png" : "../Assets/images/Icon fa-solid-eye.png"}
              alt={params.data.canEdit ? "edit" : "view"}
                style={iconStyle}
                onClick={() => Edit(params.data.patientID, params.data.uniqueEncryptedPatientId, params.data.abstractionDate, params.data.isLocked ?? false, params.data.submit, params.data.centerId, params.data.createdBy, params.data.roundId, params.data.activeRound, params.data.crossWalk, params.data.centerRoundId, params.data.isEligibilityCriteriaLocked, params.data.canEdit)}
              title={params.data.canEdit ? "Edit Patient" : "View Patient"}
              />
          }
        </>
      ),
      cellClass: "td", //resizable: false
    },
    //Eligibility Criteria Section UnLock
    {
      headerName: "Eligibilty Criteria Locked",
      width: 150,
      className: " td-th-width",
      cellRenderer: (params) => (
        <>
          {
            params?.data?.canUnlockEligibilityCriteria && <img
              src="../Assets/images/Icon-awesome-key.png"
              alt="Unlock"
              style={iconStyle}
              title="Unlock"
              onClick={() => { UnlockClick(params.data.patientID, LockType.EligibilityCriteriaLock) }}
            />
          }
          {
            params?.data?.isEligibilityCriteriaLocked && <img
              src="../Assets/images/lock.png"
              alt="Lock"
              style={iconStyle}
              title="Locked"
            />
          }
        </>
      ),
      cellClass: "td", //resizable: false
    },
    // Unlock
    {
      headerName: "Patient Locked",
      width: 130,
      className: " td-th-width",
      cellRenderer: (params) => (
        <>
          {params?.data?.canUnlock && <img
            src="../Assets/images/Icon-awesome-key.png"
            alt="Unlock"
            style={iconStyle}
            title="Unlock"
            onClick={() => { UnlockClick(params.data.patientID, LockType.PatientLock) }}
          />}
          {
            params?.data?.isLocked && <img
              src="../Assets/images/lock.png"
              alt="Lock"
              style={iconStyle}
              title="Locked"
            />
          }
        </>
      ),
      cellClass: "td", //resizable: false
    },
    //Export
    {
      width: 60,
      className: " td-th-width",
      cellRenderer: (params) => (
        params?.data?.isLocked && <img
          src="../Assets/images/file.png"
          alt="Export Patient"
          style={iconStyle}
          title="Export Patient"
          onClick={() => { ExportData(params.data.patientID, 1, params.data.centerId ,params.data.uniqueEncryptedPatientId ) }}
        />
      ),
      cellClass: "td", //resizable: false
    },
    //Export status
    {
      width: 60,
      className: " td-th-width",
      cellRenderer: (params) => (params?.data?.isLocked && //sifter 11153
        <img
          src={params?.data?.exported ? "../Assets/images/green-radio.png" : "../Assets/images/red-radio.png"}
          alt={params?.data?.exported ? "Exported" : "Not Exported"}
          style={iconStyle}
          title={params?.data?.exported ? "Exported" : "Not Exported"}
        />
      ),
      cellClass: "td", //resizable: false
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
    // flex: 1,
    cellClass: "td no-border",
    //headerClass: 'ag-cell-header'
  };
  const gridOptions = {
    columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
    pagination: true,
    paginationPageSize: 50//DefaultAGGridProps.ROWS_PER_PAGE,
  };
  const agGridLocaleText = {
    noRowsToShow: DefaultAGGridProps.NOT_FOUND,
  };
  const GetTableHeight = () => {
    return 100 + (filteredPatients?.length < 4
      ? 50 * 4
      : filteredPatients?.length < 51
        ? 50 * filteredPatients?.length
        : 50 * 50);
  };
  const CloseModal = () => {
    setViewSummary(false);
  }
  const ViewSummary = () => {
    setViewSummary(true);
  }
  const ShowAlertBox = async (message) => {
    await ShowActionPopUp(
      "Alert",
      message,
      "Ok",
      true
    );
    return;
  };
  const ExportData = async (patientId, exportType, cID, uniquePID) => {
    try {
      //export all
      if (patientId === 0) {
        let lockedPts = [];
        //atleast 1 pt present
        if (allPatients.length > 0) {
          lockedPts = allPatients.filter(a => a.isLocked || a.patientStatus || a.patientStatus === false); 
        }
        if (!(lockedPts.length > 0)) {
          await ShowAlertBox(PatientDashboard_Msgs.ExportAllAlertMsg);
          return;
        }
      }
      //end      
      ToggleLoader(true)
      const response = await PatientService.GetPatientDataToExport(patientId, exportType, userId, cID);
      ToggleLoader(false)

      const { status } = response;
      if (status !== HTTPResponse.OK) {

        if (status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else
          throw response.error;
      }
      else {
        const zipFileName = patientId === 0 ?
          exportType === 1 ? "AllPatients" : "AllTerminatedPatients"
          : exportType === 1 ? `Patient_${uniquePID}` : "";

        await DownloadPatientData(response.data, zipFileName)

        //update export staus image in grid
        if (patientId !== 0) {
          const updatedList = allPatients.map((pt) => ({
            ...pt,
            exported: pt.patientID === patientId ? true : pt.exported,
          }));
          setAllPatients(updatedList);
        }
        else
          await GetAllPatients();

        ShowToast(PatientDashboard_Msgs.ExportSuccess, ToastMessageType.Success);
      }


    } catch {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const DownloadPatientData = async (dataSet, zipFileName) => {
    try {
      const zip = new JSZip();
      const excelFiles = [];
      const wopts = { bookType: 'xlsx', bookSST: true, type: 'array' };

      let wbArr = [];
      let wb;
      const asyncTasks = dataSet?.map(({ name, isMain, tableData, columns }) => {
        //CREATE WORKSHEET for each table
        const ws = XLSX.utils.json_to_sheet(tableData, { header: columns });


        if (isMain) {
          //CREATE WORKBOOK only for the main page
          wb = XLSX.utils.book_new();
          wbArr = [...wbArr]
          wbArr.push(wb)
        }
        //APPEND SHEET TO CURRENT WORKBOOK
        XLSX.utils.book_append_sheet(wb, ws, name);//Workbook, Worksheet, Sheet name
      });
      //Use XLSX.write to convert the Excel file content to array buffer
      wbArr.forEach((sheet) => {
        const blob = XLSX.write(sheet, wopts);
        // Name the workbook adverse_events if its ae_crs
        excelFiles.push({ name: sheet.SheetNames[0] === "CRS" ? "Adverse Events" : (sheet.SheetNames[0] === "TT Characteristics_36" ?"TT Characteristics":sheet.SheetNames[0]), file: blob });
      })

      await Promise.all(asyncTasks);

      excelFiles.forEach(({ name, file }) => {
        zip.file(`${name}.xlsx`, file, { base64: true });
      });

      zip.generateAsync({ type: 'blob' }).then(async (content) => {
        // Save the ZIP file on the client-side
        saveAs(content, zipFileName);

        // Create a File object from the blob content
        const zipFile = new File([content], zipFileName);

        // Send the ZIP file to the server
        const formData = new FormData();
        formData.append('zipFile', zipFile);

        try {
          // Assuming PatientService.SaveZipFile is an asynchronous function that makes a POST request to the server
          const response = await PatientService.SaveZipFile(formData);
          const { status } = response;
          if (status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          }
          else if (status === HTTPResponse.OK) {
            await GetAllPatients();
          }
          else
            throw response.error;


          //console.log('ZIP file sent to the server successfully.');
        } catch (error) {
          //console.error('Error sending ZIP file to the server:', error);
        }
      });

    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed)
    }
  }
  /* <summary>
  date: 24-08-2024
  Name: AP
  description: Status dropwdown event handler
  <summary>*/
  const HandleStatusChange = (statusId, status) => {
    setSelectedStatus(status);
    setSelectedStatusId(statusId)
  }

  const Download = async (type) => {
    try {
      ToggleLoader(true)
      const response = await PatientService.DownloadFile(type);
      ToggleLoader(false)
      if (response != null) {
        const { status } = response;
        if (status !== HTTPResponse.OK) {
          if (status === HTTPResponse.Unauthorized)
            HandleSessionTimeout();
          else if (status === HTTPResponse.BadRequest)
            throw response.error;
        } else {
          const filePath = Config.apiUrl + "/" + response.data;
          //"https://localhost:7138/eMMPowerDataDictionary/DataDictionary.zip";

          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = filePath;
          link.setAttribute("download", filePath);
          if (type === UrlType.DataDictionary) {
            link.download = "DataDictionary.zip";
          }
          else if (type === UrlType.UserManual) {
            link.download = "UserManual.pdf";
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          ShowToast(PatientDashboard_Msgs.DownloadSuccess, ToastMessageType.Success);
        }
      }
    } catch {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }
  return (
    <>
      <section className="patient-details-section">
        <PatientDetails />
      </section>
      <section className="main-common-page-section main-common-page-section-min-ht">
        <div className="container">
          <div className="summary-tabs">
            <div className="summary-tabs-div" onClick={ViewSummary} title="Summary" style={{width:"164px"}}>
              <img width="23px" src="../Assets/images/patient-summary.png" alt="" />
              <p>View Patient Summary</p>
            </div>
            {/*{(userRole === Role.Admin) &&*/}
            <>
              <div className="summary-tabs-div" onClick={() => { ExportData(0, 1, (userRole == Role.Admin ? 0 : centerId)) }} title="Export Patients" style={{ width: "166px" }}>
                <img width="30px" src="../Assets/images/export-patients.png" alt="" />
                <p>Export All Patients</p>
              </div>
              <div className="summary-tabs-div" title="Data Dictionary" onClick={() => Download(UrlType.DataDictionary)} style={{ width: "194px" }}>
                <img width="30px" src="../Assets/images/export-terminated-patients.png" alt="" />
                <p>Data Dictionary</p>
              </div>
              <div className="summary-tabs-div" title="User Manual" onClick={() => Download(UrlType.UserManual)} style={{ width: "194px" }}>
                <img width="30px" src="../Assets/images/export-terminated-patients.png" alt="" />
                <p>User Manual</p>
              </div>
            </>
            {/*}*/}
          </div>
          <div className="para-div">
            <p>
              Thank you for participating in this chart review study. If you have any
              questions regarding the use of this form, please contact the Analysis
              Group project team via emmpower@analysisgroup.com.
            </p>
          </div>
          {!loading &&
            <div className="row search-add-btn">
              <div className="col-6 col-sm-5 col-md-5 col-lg-4">
                <div className="searchBar">
                  <input
                    className="searchQueryInput-class"
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Patient ID"
                    ref={searchRef}
                    onChange={HandleSearch}
                  />
                  <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit"
                  >
                    <img src="../Assets/images/Icon-feather-search.png" width="20px" />
                  </button>
                </div>
              </div>

              <div className="col-12 col-sm-5 col-md-3 col-lg-3">
                <div className="">
                  <span><b>Status: </b></span>
                  <Dropdown className='custom-dropdown-caret' >
                    <Dropdown.Toggle variant="" id="dropdown-basic" className="custom-dropdown-padding w150 custom-dropdown-button">
                      {selectedStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu" >
                      {statusDropdown.map(s => (
                        <Dropdown.Item
                          as="button"
                          name="activeRoundDropdown"
                          key={s.value}
                          value={s.label}
                          className={`custom-dropdown-item ${s.label === selectedStatus ? 'custom-dropdown-item-selected' : undefined}`}
                          onClick={() => HandleStatusChange(s.value, s.label)}
                        >
                          {s.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="">
                  <button
                    className="btn btn-outline-light btn-lg add-new-patient"
                    type="submit" onClick={AddNew}
                  >
                    + Add New Patient
                  </button>
                </div>
              </div>
            </div>}

          <div className="user-tab">
            {loading ? (
              <></>
            )
              :
              (<div
                className="ag-theme-alpine "
                style={{
                  height: filteredPatients?.length === 0 ? 322 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={filteredPatients}
                  gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                  onPaginationChanged={() => {
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
              )
            }
          </div>
        </div>
      </section>


      {viewSummary && (
        <PatientSummary
          CloseModal={CloseModal}
        />
      )}

      {showUnlockConfirm &&
        <ConfirmPopUp
          YesClick={YesClick}
          NoClick={NoClick}
          title={unlockType === LockType.PatientLock ?
            PatientDashboard_Msgs.UnlockConfirmMsg :
            PatientDashboard_Msgs.UnlockEligibilityConfirmMsg}
        />
      }


    </>
  );
};

export default Dashboard;
